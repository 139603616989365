import { getDownloadURL, listAll, ref, deleteObject } from "@firebase/storage";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import MainHeader from "../../../Components/Headers/MainHeader";
import { storage } from "../Configuration/firebase";
import UploadContentInBulkFolderWise from "./UploadContentInBulkFolderWise";
import styles from "./FolderData.module.css";
import { MdContentCopy } from "react-icons/md";
import { toast } from "react-toastify";
import { IoMdRefresh } from "react-icons/io";
import { AiOutlineDelete } from "react-icons/ai"; // Import delete icon

const FolderData = () => {
    const { bucketIdentifier, folderIdentifier } = useParams();
    const [isUploadingNewOpen, setIsUploadingNewOpen] = useState(false);
    const [folderData, setFolderData] = useState([]);

    function copyToClipboardModern(text) {
        navigator.clipboard
            .writeText(text)
            .then(() => toast("URL copied to clipboard"))
            .catch((err) => console.error("Failed to copy text:", err));
    }

    const fetchFilesInFolder = async (userId, folderName) => {
        try {
            const folderRef = ref(storage, `uploads/${userId}/${folderName}`);

            // List all files in the folder
            const result = await listAll(folderRef);

            // Get file URLs
            const fileUrls = await Promise.all(
                result.items.map(async (itemRef) => {
                    const fileUrl = await getDownloadURL(itemRef);
                    return { name: itemRef.name, url: fileUrl, ref: itemRef };
                })
            );
            setFolderData(fileUrls);
            // console.log("Files in folder:", fileUrls);
        } catch (error) {
            console.error("Error fetching files:", error.message);
        }
    };

    const deleteFile = async (fileRef, fileName) => {
        try {
            await deleteObject(fileRef);
            toast(`${fileName} deleted successfully`);
            fetchFilesInFolder(bucketIdentifier, folderIdentifier); // Refresh file list
        } catch (error) {
            console.error("Error deleting file:", error.message);
            toast.error(`Failed to delete ${fileName}`);
        }
    };

    useEffect(() => {
        fetchFilesInFolder(bucketIdentifier, folderIdentifier);
    }, [folderIdentifier]);

    return (
        <div>
            <MainHeader />

            {isUploadingNewOpen && (
                <UploadContentInBulkFolderWise
                    folderIdentifier={folderIdentifier}
                    isOpen={isUploadingNewOpen}
                    onClose={() => setIsUploadingNewOpen(false)}
                />
            )}
            <div className={styles.mainHeader}>
                All Data ({folderData?.length} files available)
                <div className={styles.headerActions}>
                    <button
                        onClick={() => fetchFilesInFolder(bucketIdentifier, folderIdentifier)}
                        className={styles.refreshBtn}
                    >
                        <IoMdRefresh size={26} />
                    </button>
                    <button
                        onClick={() => setIsUploadingNewOpen(true)}
                        className={styles.uploadNewBtn}
                    >
                        Upload New One
                    </button>
                </div>
            </div>
            <div className={styles.gallaryWrapper}>
                {folderData?.map((item, index) => (
                    <div key={index} >
                        <div className={styles.imageWrapper} >
                            <a href={item?.url} target="_blank" >
                                <img src={item?.url} alt={item?.name} />
                            </a>
                        </div>
                        <div className={styles.actionWrapper}>
                            <div className={styles.filename}>{item?.name}</div>
                            <div className={styles.actionIcons}>
                                <div
                                    className={styles.copyIcon}
                                    onClick={() => copyToClipboardModern(item?.url)}
                                >
                                    <MdContentCopy size={18} />
                                </div>
                                <div
                                    className={styles.deleteIcon}
                                    onClick={() => deleteFile(item.ref, item.name)}
                                >
                                    <AiOutlineDelete size={18} />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FolderData;

import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import MainHeader from '../../Components/Headers/MainHeader'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './OpAllBatches.module.css'

const OpAllBatches = () => {
    const authCtx = useContext(AuthContext)
    const [allBatches, setAllBatches] = useState([])
    const opGetAllBatches = async () => {
        const opGetAllBatchesResponse = await fetch(BASE_URL_API + "/opGetAllBatches",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!opGetAllBatchesResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const opGetAllBatchesRespo = await opGetAllBatchesResponse.json()
            if (opGetAllBatchesRespo.status === "success") {
                setAllBatches(opGetAllBatchesRespo?.response)
            } else {
                if (opGetAllBatchesRespo?.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(opGetAllBatchesRespo)
        }
    }

    useEffect(() => {
        opGetAllBatches()
    }, [])



    return (
        <div>
            <MainHeader />
            <div className={styles.mainWrapper}>
                <div>
                    <div>

                    </div>
                    <div className={styles.allRows}>
                        {allBatches?.map((item, index) => (
                            <div key={index} className={styles.eachRowWrapper}>
                                <div className={styles.batchIdentifier}>
                                    <Link to={`/marketplace/order-processing/batch/${item?.batchIdentifier}`}> {item?.batchIdentifier}</Link>
                                </div>
                                <div className={styles.totalOrders}>
                                    {item?.totalOrders}
                                </div>
                                <div className={styles.createdOn}>
                                    {item?.createdOn?.substring(0, 10)}
                                </div>
                                <div className={styles.createdOn}>
                                    {item?.createdOn?.substring(11, 22)}
                                </div>
                                <div className={styles.action}>
                                    <Link to={`/marketplace/order-processing/batch/${item?.batchIdentifier}`}> View</Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OpAllBatches
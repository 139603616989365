import React, { useContext, useEffect, useState } from 'react'
import { FaFilter } from 'react-icons/fa6'
import { MdCheckBox, MdCheckBoxOutlineBlank, MdToggleOff, MdToggleOn } from 'react-icons/md'
import { toast } from 'react-toastify'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './ViewEachVendorProductsAdded.module.css'

const ViewEachVendorProductsAdded = (props) => {
    const { toggleNavWrapper, vendorIdentifier } = props
    const authCtx = useContext(AuthContext)
    const [selectedAddedItems, setSelectedAddedItems] = useState([])

    const [addedProducts, setAddedProducts] = useState([])



    const addedItemsSelectionHandler = (k) => {
        if (selectedAddedItems.some(item => item.productIdentifier === k.productIdentifier)) {
            // Remove the item if it's already selected
            setSelectedAddedItems(
                selectedAddedItems.filter(item => item.productIdentifier !== k.productIdentifier)
            );
        } else {
            // Add the item if it's not selected
            setSelectedAddedItems([...selectedAddedItems, k]);
        }
    };


    const getVendorAddedProducts = async () => {
        const getVendorAddedProductsResponse = await fetch(BASE_URL_API+"/getVendorAddedProducts",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                vendorIdentifier : vendorIdentifier
            })
        });

        if(!getVendorAddedProductsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getVendorAddedProductsRespo = await getVendorAddedProductsResponse.json()
            if(getVendorAddedProductsRespo?.status==="success") {
                setAddedProducts(getVendorAddedProductsRespo?.response)
            } else {
                if(getVendorAddedProductsRespo?.message==="token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(getVendorAddedProductsRespo)
        }

    }

    useEffect(() => {
        getVendorAddedProducts()
    }, [])
    


    return (
        <div>
            <div className={styles.mainHeader}>
                <div className={styles.mhLeft} >
                    <div className={styles.toggleBtn}>
                        <button onClick={() => toggleNavWrapper()}>
                            <MdToggleOff size={24} /> Listing Added Products
                        </button>
                    </div>
                    <div className={styles.searchbarWrapper}>
                        <input placeholder="Search For any product" />
                    </div>
                </div>
                <div className={styles.mhRight}>
                    <div>
                        <FaFilter />
                    </div>
                    <div>
                        <button className={styles.addItems} >Add {selectedAddedItems?.length} items</button>
                    </div>
                </div>
            </div>
            <div className={styles.productTableWrapper}>
                <div className={styles.productHeaderWrapper}>
                    <div className={styles.checkBox}>
                        {selectedAddedItems?.length === addedProducts?.length ?
                            <MdCheckBox size={22} />
                            :
                            <MdCheckBoxOutlineBlank size={22} />
                        }
                    </div>
                    <div className={styles.productTitle}>
                        Product Title
                    </div>
                    <div className={styles.bestPrice}>
                        Best Price
                    </div>
                    <div className={styles.stock}>
                        Stock
                    </div>
                </div>
                <div className={styles.productBodyWrapper}>
                    {addedProducts?.map((item, index) => (
                        <div key={index} className={styles.eachProductWrapper}>
                            <div className={styles.checkBox} onClick={() => addedItemsSelectionHandler(item)}>
                                {selectedAddedItems.some(itemToFind => itemToFind.productIdentifier === item.productIdentifier) ? (
                                    <MdCheckBox size={20} />
                                ) : (
                                    <MdCheckBoxOutlineBlank size={20} />
                                )}
                            </div>

                            <div className={styles.productTitle}>
                                {item?.productTitle}
                            </div>
                            <div className={styles.bestPrice}>
                                {item?.bestPrice}
                            </div>
                            <div className={styles.stock}>
                                {item?.stock}
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </div>
    )
}

export default ViewEachVendorProductsAdded
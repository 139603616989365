import React, { useContext, useState } from 'react'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './InvoiceEditor.module.css'

const InvoiceEditor = (props) => {
    const authCtx = useContext(AuthContext)
    const { isOpen, toggleInvoiceEditor, orderIdentifier } = props
    const [invoiceDate, setInvoiceDate] = useState('')
    const [dueDate, setDueDate] = useState('')
    const [bankAccountDetails, setBankAccountDetails] = useState('ICICI')
    const [placeOfSupply, setPlaceOfSupply] = useState('')
    const [dueAmount, setDueAmount] = useState('')
    const [receivingBank, setReceivingBank] = useState('')
    const [termsAndConditions, setTermsAndConditions] = useState('')


    const [tncTxt, setTncTxt] = useState(`
        Subject to our home Jurisdiction.
        Our Responsibility Ceases as soon as goods leaves our Premises.
        Goods once sold will not taken back.
        Delivery Ex-Premises.`
    )

    const updateInvoiceDetails = async () => {
        const updateInvoiceDetailsResponse = await fetch(BASE_URL_API+"/updateInvoiceDetails",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                orderIdentifier : orderIdentifier,
                invoiceDate : invoiceDate,
                placeOfSupply : placeOfSupply,
                dueDate : dueDate,
                dueAmount : dueAmount,
                receivingBank : receivingBank,
                termsAndConditions : termsAndConditions.trim(),
            })
        });

        if(!updateInvoiceDetailsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateInvoiceDetailsRespo = await updateInvoiceDetailsResponse.json()
            if(updateInvoiceDetailsRespo.status==="success") {

            } else {
                if(updateInvoiceDetailsRespo.message==="token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(updateInvoiceDetailsRespo)
        }
    }




    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer}>
                    <div className={styles.mainContent}>
                        <div className={styles.mainHeader}>
                            Invoice Editor
                        </div>
                        <div className={styles.mainBody}>

                            <div className={styles.eachSectionWrapper}>
                                <div className={styles.eachDateWrapper}>
                                    <div>
                                        Invoice Date
                                    </div>
                                    <div>
                                        <input type="date" />
                                    </div>
                                </div>
                                <div className={styles.eachDateWrapper}>
                                    <div>
                                        Due Date
                                    </div>
                                    <div>
                                        <input type="date" />
                                    </div>
                                </div>

                                <div className={styles.eachDateWrapper}>
                                    <div>

                                        Bank Account Details
                                    </div>
                                    <div>
                                        <select>
                                            <option>ICICI</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.eachSectionWrapper}>
                                <div className={styles.tncTitle}>Terms & Conditions</div>
                                <div className={styles.tncForm}>
                                    <textarea rows={6}
                                        placeholder="Enter terms and conditions..."
                                        value={tncTxt}
                                        onChange={(e) => setTncTxt(e.target.value)}
                                    >

                                    </textarea>
                                </div>
                            </div>

                        </div>
                        <div className={styles.mainFooter}>
                            <div className={styles.actionBtnWrapper}>
                                <button onClick={() => toggleInvoiceEditor()}>Cancel</button>
                                <button className={styles.updateBtn} onClick={() => updateInvoiceDetails()}>Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default InvoiceEditor
import { getDownloadURL, listAll, ref } from '@firebase/storage';
import React, { useContext, useEffect, useState } from 'react';
import { FaFolder } from 'react-icons/fa6';
import { MdCreateNewFolder } from 'react-icons/md';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import AddNewFolderInBucket from '../../../Components/CloudStorage/BucketMaster/AddNewFolderInBucket';
import MainHeader from '../../../Components/Headers/MainHeader';
import { BASE_URL_API } from '../../../References/urls';
import AuthContext from '../../../Store/auth-context';
import { storage, auth } from '../Configuration/firebase';
import styles from './EachBucket.module.css'

const EachBucket = () => {
    const { bucketIdentifier } = useParams();
    const authCtx = useContext(AuthContext)
    const [isCollectingFolders, setIsCollectingFolders] = useState(false)
    const [allFolders, setAllFolders] = useState([])
    const [isNewFolderAddingOpen, setIsNewFolderAddingOpen] = useState(false)

    const fetchFolders = async (userId) => {
        try {
            // Reference to the user's root folder
            const userFolderRef = ref(storage, `uploads/${userId}`);

            // Fetch all folder references in the user's directory
            const result = await listAll(userFolderRef);

            // Extract folder names
            const folders = result.prefixes.map((folderRef) => folderRef.name);
            console.log("Folders:", folders);
            setAllFolders(folders)
            return folders;
        } catch (error) {
            console.error("Error fetching folders:", error.message);
        }
    };

    useEffect(() => {
        // fetchFolders(bucketIdentifier);
        getStorageFolder()
    }, [bucketIdentifier]);

    const fetchFilesInFolder = async (userId, folderName) => {
        try {
            const folderRef = ref(storage, `uploads/${userId}/${folderName}`);

            // List all files in the folder
            const result = await listAll(folderRef);

            // Get file URLs
            const fileUrls = await Promise.all(
                result.items.map(async (itemRef) => {
                    const fileUrl = await getDownloadURL(itemRef);
                    return { name: itemRef.name, url: fileUrl };
                })
            );

            console.log("Files in folder:", fileUrls);
            return fileUrls;
        } catch (error) {
            console.error("Error fetching files:", error.message);
        }
    };


    const fetchFoldersAndFiles = async (userId) => {
        try {
            // Fetch folders
            const folders = await fetchFolders(userId);

            // Fetch files inside each folder
            const folderContents = await Promise.all(
                folders.map(async (folderName) => {
                    const files = await fetchFilesInFolder(userId, folderName);
                    return { folderName, files };
                })
            );

            setAllFolders(folderContents)
            console.log("Folders and their files:", folderContents);
            return folderContents;
        } catch (error) {
            console.error("Error fetching folder contents:", error.message);
        }
    };


    const getStorageFolder = async () => {
        setIsCollectingFolders(true)
        const getStorageFolderResponse = await fetch(BASE_URL_API + "/getStorageFolder",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    bucketIdentifier: bucketIdentifier
                })
            });

        if (!getStorageFolderResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getStorageFolderRespo = await getStorageFolderResponse.json()
            if (getStorageFolderRespo?.status === "success") {
                setAllFolders(getStorageFolderRespo?.response)
            } else {
                if (getStorageFolderRespo?.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAllFolders([])
                }
            }
            console.log(getStorageFolderRespo)
        }
        setIsCollectingFolders(false)
    }

    const onSuccessfullyCreatingNew = () => {
        toast(`Successfully created a new storage!`)
        setIsNewFolderAddingOpen(false)
        getStorageFolder()

    }


    return (
        <div>
            <MainHeader />
            <AddNewFolderInBucket isOpen={isNewFolderAddingOpen} onClose={() => setIsNewFolderAddingOpen(false)} onSuccess={() => onSuccessfullyCreatingNew()} />
            <div className={styles.mainContainer} >
                <div className={styles.mainContent}>
                    <div className={styles.mainHeader}>
                        <div className={styles.getDataBtn}>
                            <button>Show Folders</button>
                            {/* <button onClick={() => fetchFoldersAndFiles(bucketIdentifier)}>Show Folders & Files</button> */}
                        </div>
                        <div className={styles.addNewFolder} onClick={() => setIsNewFolderAddingOpen(true)} >
                            <MdCreateNewFolder size={24} />
                        </div>
                    </div>
                    {allFolders?.map((item, index) => (
                        <Link to={`/storage/buckets/${bucketIdentifier}/${item?.folderName}`} key={index} className={styles.eachFolder}>
                            <div className={styles.folder}>
                                <FaFolder size={22} color="rebeccapurple" />
                            </div>
                            <div className={styles.folderName}>
                                {item?.folderName}
                            </div>
                        </Link>
                    ))}
                </div>
            </div>

        </div>
    )

};

export default EachBucket;

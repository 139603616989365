import React from 'react'
import { MdCheckBox, MdCheckBoxOutlineBlank, MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { BASE_URL_API } from '../../References/urls'
import styles from './EachRowViewBulkOrder.module.css'


const EachRowViewBulkOrder = (props) => {
    const { item, count, selectedItems, rowSelectionHandler } = props






    return (
        <div className={styles.mainWrapper}>
            <div onClick={() => rowSelectionHandler(item)} className={styles.sn} >
                {selectedItems.some((itemThis) => itemThis?.orderIdentifier === item?.orderIdentifier) ? (
                    <MdOutlineCheckBox size={24} />
                ) : (
                    <MdOutlineCheckBoxOutlineBlank size={24} />
                )}
            </div>
            <div className={styles.orderId}>
                <Link to={`/marketplace/bulk-order/${item?.orderIdentifier}`}>
                    {item?.orderIdentifier}
                </Link>
            </div>
            <div className={styles.date}>
                {item?.placedOn?.substring(0, 10)}
            </div>
            <div className={styles.date}>
                {item?.placedOn?.substring(11, 19)}
            </div>
            <div className={styles.item}>
                {item?.itemTitle}
            </div>
            <div className={styles.pointsUsed}>
                {item?.pointsUsed}
            </div>
            <div className={styles.phone}>
                {item?.receiverPhone}
            </div>
            <div className={styles.address}>
                {item?.deliveryAddress}
            </div>
            <div className={styles.pincode}>
                {item?.deliveryPincode}
            </div>
            <div className={styles.currentStatus}>
                {item?.currentStatus}
            </div>
        </div>
    )
}

export default EachRowViewBulkOrder
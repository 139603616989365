import { listAll, ref } from '@firebase/storage';
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import MainHeader from '../../../Components/Headers/MainHeader';
import { storage } from '../Configuration/firebase';

const AllBuckets = () => {

    // const fetchFolders = async (userId) => {
    //     try {
    //         const userFolderRef = ref(storage, `uploads/${userId}`);

    //         // List all items (files and subfolders)
    //         const result = await listAll(userFolderRef);

    //         // Extract unique folder names from the paths
    //         const folders = result.prefixes.map((folderRef) => folderRef.name);
    //         console.log("Folders:", folders);

    //         return folders;
    //     } catch (error) {
    //         console.error("Error fetching folders:", error.message);
    //     }
    // };

    // useEffect(() => {
    //     fetchFolders()
    // }, [])



    return (
        <div>
            <MainHeader />
            <div>
                AllBuckets
            </div>
            <div>
                <Link to={`/storage/buckets/iTK28rvxEqdZ2pYjaOKK2KYT80T2`}>Go To Bucket</Link>
            </div>
        </div>
    )
}

export default AllBuckets
import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './GenerateChallans.module.css'
import PreviewChallan from './PreviewChallan'

const GenerateChallans = () => {
    const authCtx = useContext(AuthContext)
    const [isPreviewOpen, setIsPreviewOpen] = useState(false)
    const toggleInvoiceGenerator = () => setIsPreviewOpen(false)
    const [userData, setUserData] = useState([])
    const [allClients, setAllClients] = useState([])
    const [selectedClient, setSelectedClient] = useState(null)
    console.log(selectedClient)

    const [activeUser, setActiveUser] = useState('')

    const getAllClients = async () => {
        const getAllClientsResponse = await fetch(BASE_URL_API + "/getAllClients",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getAllClientsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllClientsRespo = await getAllClientsResponse.json();
            if (getAllClientsRespo.status === "success") {
                setAllClients(getAllClientsRespo.response)
            } else {
                if (getAllClientsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAllClients([])
                }
            }
        }

    }

    useEffect(() => {
        getAllClients()
    }, [])



    const getAllClientsUser = async () => {
        const getAllClientsUserResponse = await fetch(BASE_URL_API + "/getAllClientsUser",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    clientIdentifier: selectedClient
                })
            });

        if (!getAllClientsUserResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllClientsUserRespo = await getAllClientsUserResponse.json();
            if (getAllClientsUserRespo.status === "success") {
                setUserData(getAllClientsUserRespo.response)
            } else {
                if (getAllClientsUserRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setUserData([])
                }
            }
            console.log(getAllClientsUserRespo)
        }
    }

    useEffect(() => {
        if (selectedClient !== null) {
            getAllClientsUser()
        }
    }, [selectedClient])

    const challanPreviewHandler = (k) => {
        setActiveUser(k)
        setIsPreviewOpen(true)
    }

    return (
        <div className={styles.mainWrapper}>
            {isPreviewOpen && <PreviewChallan isOpen={isPreviewOpen}  toggleInvoiceGenerator={toggleInvoiceGenerator} activeUser={activeUser} />}
            <div className={styles.mainHeader}>
                <div className={styles.mainHeaderTitle}>
                    <select onChange={(e) => setSelectedClient(e.target.value)} >
                        <option value={null}>Select A Client</option>
                        {allClients?.map((item, index) => (
                            <option value={item?.clientIdentifier} key={index} >{item?.brandName}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div className={styles.mainBody}>
                <div className={styles.tableHeaderWrapper}>
                    <div className={styles.sn} >#</div>
                    <div className={styles.firstName}>Full Name</div>
                    <div className={styles.phoneNumber}>Phone Number</div>
                    <div className={styles.address}>Address</div>
                    <div className={styles.product}>Product</div>
                    <div className={styles.email}>Work E-mail</div>
                    <div className={styles.action}>Action</div>
                </div>
                <div className={styles.tableBodyWrapper}>
                    {userData?.map((item, index) => (
                        <div key={index} className={styles.eachRowWrapper}>
                            <div className={styles.sn} >{index + 1}</div>
                            <div className={styles.firstName}>{item?.firstName}</div>
                            <div className={styles.phoneNumber}>{item?.phoneNumber}</div>
                            <div className={styles.address}>{item?.address}</div>
                            <div className={styles.product}>{item?.city}</div>
                            <div className={styles.email}>{item?.workEmail}</div>
                            <div className={styles.action}>
                                <button onClick={() => challanPreviewHandler(item)} >Preview </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default GenerateChallans
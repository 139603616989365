import React, { useContext, useEffect, useState } from 'react'
import { FaFilter } from 'react-icons/fa6'
import { Link } from 'react-router-dom'
import MainHeader from '../../Components/Headers/MainHeader'
import EachRowAllProducts from '../../Components/Marketplace/EachRowAllProducts'
import EditProduct from '../../Components/Marketplace/EditProduct'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './ViewProducts.module.css'

const ViewProducts = () => {
    const authCtx = useContext(AuthContext)
    const [allProducts, setAllProducts] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [editingItem, setEditingItem] = useState(null)
    const [isFilterVisible, setIsFilterVisible] = useState(false)
    const [filteredData, setFilteredData] = useState([])
    const [searchKey, setSearchKey] = useState('')


    const editingItemHandler = (k) => {
        console.log(k)
        setEditingItem(k)
    }
    const closeEditingItem = () => setEditingItem(null)

    const getEcomProducts = async () => {
        setIsLoading(true)
        const getEcomProductsResponse = await fetch(BASE_URL_API + "/getEcomProducts",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getEcomProductsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getEcomProductsRespo = await getEcomProductsResponse.json()
            if (getEcomProductsRespo.status === "success") {
                setAllProducts(getEcomProductsRespo.response)
                setFilteredData(getEcomProductsRespo.response)
            } else {
                if (getEcomProductsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(getEcomProductsRespo)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        getEcomProducts()
    }, [])

    window.onclick = event => event.target.id === "mcmsfjhd43ds" ? setIsFilterVisible(false) : null
    document.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
            setIsFilterVisible(false)
        }
    });

    const filterData = (data, searchKey) => {
        setFilteredData(data.filter(item =>
            Object.values(item).some(value =>
                value?.toString().toLowerCase().includes(searchKey.toLowerCase())
            )
        ))
    }

    useEffect(() => {
        filterData(allProducts, searchKey)
    }, [searchKey])


    return (
        <div>
            <MainHeader />
            {!!editingItem && <EditProduct onCompletion={getEcomProducts} isOpen={!!editingItem} item={editingItem} closeEditingItem={closeEditingItem} />}
            {isFilterVisible && <div className={styles.filterWrapper}>
                <div className={styles.filterContainer} id="mcmsfjhd43ds">
                    <div className={styles.filterContent}>
                        <div className={styles.filters}>
                            <div>
                                <select>
                                    <option>Select Brand</option>
                                </select>
                            </div>
                            <div>
                                <select>
                                    <option>Select Category</option>
                                </select>
                            </div>
                            <div>
                                <select>
                                    <option>Select Sub Category</option>
                                </select>
                            </div>
                        </div>
                        <div className={styles.resultRow}>
                            showing {filteredData?.length} / {allProducts?.length} results
                        </div>
                    </div>
                </div>
            </div>}
            <div className={styles.headerWrapper}>
                <div className={styles.searchBar}>
                    <input placeholder="Search for anything..." onChange={(e) => setSearchKey(e.target.value)} />
                </div>
                <div className={styles.headerRightWrapper}>
                    <button onClick={() => setIsFilterVisible(true)} className={styles.filterBtn} >
                        <FaFilter size={18} color="rebeccapurple" />
                    </button>
                    <Link to={`/marketplace/add-products-in-bulk`} className={styles.addBtn}>
                        Add Product In Bulk
                    </Link>
                </div>

            </div>
            <div className={styles.mainContentWrapper}>
                {isLoading ?
                    <div>
                        Hold on!
                        Collecting all products!
                    </div>
                    :
                    <div>
                        <div className={styles.tableHeader}>
                            <div className={styles.id}>
                                #
                            </div>
                            <div className={styles.title}>
                                Title
                            </div>
                            <div className={styles.brand}>
                                Brand
                            </div>
                            <div className={styles.category}>
                                Category
                            </div>
                            <div className={styles.description}>
                                Description
                            </div>
                            <div className={styles.pointsRequired}>
                                Points Required
                            </div>
                            <div>
                                Action
                            </div>
                        </div>
                        <div className={styles.tableBody}>
                            {filteredData?.map((item, index) => (
                                <EachRowAllProducts
                                    key={index}
                                    item={item}
                                    editingItemHandler={editingItemHandler}
                                    getEcomProducts={getEcomProducts}
                                />
                            ))}
                            <div className={styles.thatsAll}>
                                That's all!
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default ViewProducts
import React, { useContext, useEffect, useState } from 'react'
import MainHeader from '../../Components/Headers/MainHeader'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './CreateNewUser.module.css'

const CreateNewUser = () => {
    const authCtx = useContext(AuthContext)
    const [formData, setFormData] = useState({
        employeeIdentifierAtOrgLevel: '',
        phoneNumber: '',
        firstName: '',
        middleName: '',
        lastName: '',
        gender: '',
        firstLine: '',
        secondLine: '',
        city: '',
        state: '',
        country: 'India',
        pincode: '',
        fullAddress: '', // Initialize as an empty string
        addressType: 'CURRENT_ADDRESS'
    });

    // Helper function to construct fullAddress
    const buildFullAddress = ({ firstLine, secondLine, city, state, country, pincode }) => {
        const addressParts = [firstLine, secondLine, city, state, country, pincode].filter(Boolean);
        return addressParts.join(', ');
    };

    // Create the full address dynamically
    useEffect(() => {
        setFormData(prevData => ({
            ...prevData,
            fullAddress: buildFullAddress(prevData)
        }));
    }, [formData.firstLine, formData.secondLine, formData.city, formData.state, formData.country, formData.pincode]);


    const [isCreating, setIsCreating] = useState(false)
    const [allClients, setAllClients] = useState([])
    const [selectedClient, setSelectedClient] = useState(null)

    const getAllClients = async () => {
        const getAllClientsResponse = await fetch(BASE_URL_API + "/getAllClients",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getAllClientsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllClientsRespo = await getAllClientsResponse.json();
            if (getAllClientsRespo.status === "success") {
                setAllClients(getAllClientsRespo.response)
            } else {
                if (getAllClientsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAllClients([])
                }
            }
        }

    }

    useEffect(() => {
        getAllClients()
    }, [])

    const clearFormHandler = () => {
        setFormData({
            employeeIdentifierAtOrgLevel: '',
            phoneNumber: '',
            firstName: '',
            middleName: '',
            lastName: '',
            gender: '',
            firstLine: '',
            secondLine: '',
            city: '',
            state: '',
            country: 'India',
            pincode: '',
            fullAddress: '', // Initialize as an empty string
            addressType: 'CURRENT_ADDRESS',
            workEmail: ''
        })
    }

    const createNewUser = async () => {
        if (formData.phoneNumber.length === 10) {
            if (selectedClient !== null) {
                setIsCreating(true)
                const createNewUserResponse = await fetch(BASE_URL_API + "/createNewUser",
                    {
                        method: "POST",
                        body: JSON.stringify({
                            token: authCtx.token,
                            clientIdentifier: selectedClient,
                            employeeIdentifierAtOrgLevel: '',
                            phoneNumber: formData?.phoneNumber,
                            firstName: formData?.firstName,
                            middleName: formData?.middleName,
                            lastName: formData?.lastName,
                            gender: formData?.gender,
                            firstLine: formData?.firstLine,
                            secondLine: formData?.secondLine,
                            city: formData?.city,
                            state: formData?.state,
                            country: formData?.country,
                            pincode: formData?.pincode,
                            address: formData?.fullAddress,
                            addressType: 'CURRENT_ADDRESS',
                            workEmail: formData?.workEmail
                        })
                    });

                if (!createNewUserResponse.ok) {
                    console.log("Something went wrong : Server Error")
                } else {
                    const createNewUserRespo = await createNewUserResponse.json()
                    if (createNewUserRespo.status === "success") {
                        clearFormHandler()
                    } else {
                        if (createNewUserRespo.message === "token Expired, please login again!") {
                            authCtx.logout()
                        } else {
                            alert(createNewUserRespo.message)
                        }
                    }
                    console.log(createNewUserRespo)
                    setIsCreating(false)
                }
            } else {
                alert("Please select a client")
            }

        } else {
            alert("Please enter 10 digit phone number")
        }

    }


    return (
        <div>
            <MainHeader />
            <div className={styles.mainWrapper}>
                <div className={styles.mainHeader}>
                    <div className={styles.mainTitle}>
                        Create A New User
                    </div>
                    <div className={styles.selectClientWrapper}>
                        <select onChange={(e) => setSelectedClient(e.target.value)} >
                            <option value={null}>Select A Client</option>
                            {allClients?.map((item, index) => (
                                <option value={item?.clientIdentifier}>{item?.brandName}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className={styles.formWrapper}>
                    <div className={styles.formContainer}>
                        <div className={styles.formFieldsWrapper}>
                            <div className={styles.eachRowWrapper}>
                                <div>Phone </div>
                                <div className={styles.eachInputWrapper}>
                                    <input value={formData.phoneNumber} type="number" autoFocus
                                        onChange={(e) => setFormData(prevData => ({
                                            ...prevData,
                                            phoneNumber: e.target.value
                                        }))}
                                    />
                                </div>
                            </div>

                            <div className={styles.threeInRow}>
                                <div>
                                    <div>First Name </div>
                                    <div className={styles.eachInputWrapper}>
                                        <input value={formData.firstName}
                                            onChange={(e) => setFormData(prevData => ({
                                                ...prevData,
                                                firstName: e.target.value
                                            }))}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div>Middle Name </div>
                                    <div className={styles.eachInputWrapper}>
                                        <input value={formData.middleName}
                                            onChange={(e) => setFormData(prevData => ({
                                                ...prevData,
                                                middleName: e.target.value
                                            }))}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div>Last Name </div>
                                    <div className={styles.eachInputWrapper}>
                                        <input value={formData.lastName}
                                            onChange={(e) => setFormData(prevData => ({
                                                ...prevData,
                                                lastName: e.target.value
                                            }))}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={styles.eachRowWrapper}>
                                <div>Gender</div>
                                <div className={styles.genderWrapper}>
                                    <button className={formData.gender === "Female" ? styles.eachGenderSelected : styles.eachGender}
                                        onClick={() => setFormData(prevData => ({
                                            ...prevData,
                                            gender: "Female"
                                        }))}
                                    >
                                        Female
                                    </button>
                                    <button className={formData.gender === "Male" ? styles.eachGenderSelected : styles.eachGender}
                                        onClick={() => setFormData(prevData => ({
                                            ...prevData,
                                            gender: "Male"
                                        }))}
                                    >
                                        Male
                                    </button>
                                    <button className={formData.gender === "LGBTQ" ? styles.eachGenderSelected : styles.eachGender}
                                        onClick={() => setFormData(prevData => ({
                                            ...prevData,
                                            gender: "LGBTQ"
                                        }))}
                                    >
                                        LGBTQ
                                    </button>
                                    <button className={formData.gender === "Unknown" ? styles.eachGenderSelected : styles.eachGender}
                                        onClick={() => setFormData(prevData => ({
                                            ...prevData,
                                            gender: "Unknown"
                                        }))}
                                    >
                                        Unknown
                                    </button>
                                </div>
                            </div>

                            <div className={styles.eachRowWrapper}>
                                <div>Work E-mail</div>
                                <div className={styles.eachInputWrapper}>
                                    <input value={formData.workEmail}
                                        onChange={(e) => setFormData(prevData => ({
                                            ...prevData,
                                            workEmail: e.target.value
                                        }))}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={styles.formContainer}>
                        <div className={styles.formFieldsWrapper}>
                            <div className={styles.eachRowWrapper}>
                                <div>Address (First Line) </div>
                                <div className={styles.eachInputWrapper}>
                                    <input value={formData?.firstLine} autoFocus
                                        onChange={(e) => setFormData(prevData => ({
                                            ...prevData,
                                            firstLine: e.target.value
                                        }))}
                                    />
                                </div>
                            </div>
                            <div className={styles.eachRowWrapper}>
                                <div>Address (Second Line) </div>
                                <div className={styles.eachInputWrapper}>
                                    <input value={formData?.secondLine} autoFocus
                                        onChange={(e) => setFormData(prevData => ({
                                            ...prevData,
                                            secondLine: e.target.value
                                        }))}
                                    />
                                </div>
                            </div>

                            <div className={styles.threeInRow}>
                                <div>
                                    <div>City </div>
                                    <div className={styles.eachInputWrapper}>
                                        <input value={formData?.city}
                                            onChange={(e) => setFormData(prevData => ({
                                                ...prevData,
                                                city: e.target.value
                                            }))}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div>State </div>
                                    <div className={styles.eachInputWrapper}>
                                        <input value={formData?.state}
                                            onChange={(e) => setFormData(prevData => ({
                                                ...prevData,
                                                state: e.target.value
                                            }))}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.threeInRow}>
                                <div>
                                    <div>Pincode </div>
                                    <div className={styles.eachInputWrapper}>
                                        <input value={formData?.pincode}
                                            onChange={(e) => setFormData(prevData => ({
                                                ...prevData,
                                                pincode: e.target.value
                                            }))}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div>Country </div>
                                    <div className={styles.eachInputWrapper}>
                                        <input value={formData?.country}
                                            onChange={(e) => setFormData(prevData => ({
                                                ...prevData,
                                                country: e.target.value
                                            }))}
                                        />
                                    </div>
                                </div>
                            </div>



                            <div className={styles.eachRowWrapper}>
                                <textarea placeholder="Full address..." value={formData?.fullAddress} readOnly >

                                </textarea>
                            </div>

                        </div>
                        <div>
                            {isCreating === true ?
                                <button>Creating now...</button>
                                :
                                <button onClick={() => createNewUser()}>Create Now</button>
                            }
                        </div>
                    </div>


                </div>
            </div>

        </div>
    )
}

export default CreateNewUser
import React from 'react'

const ThatsAll = (props) => {
    const { height = 200 } = props
    return (
        <div style={{height : `${height}px`, color:'#a5a5a5', fontSize: '12px', display : 'flex', justifyContent : 'center', alignItems : 'center'}}>
            That's All!
        </div>
    )
}

export default ThatsAll
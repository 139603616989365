import React, { useContext, useEffect, useState } from 'react'
import MainHeader from '../../Components/Headers/MainHeader'
import EachRowViewBulkOrder from '../../Components/Marketplace/EachRowViewBulkOrder'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './BulkOrders.module.css'

import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'


const BulkOrders = () => {
    const authCtx = useContext(AuthContext)
    const nav = useNavigate()
    const [ordersData, setOrdersData] = useState([])
    const [selectedData, setSelectedData] = useState([])
    const handleAllSelector = () => {
        if (ordersData.length === selectedData.length) {
            setSelectedData([])
        } else {
            setSelectedData(ordersData)
        }
    }

    const getBulkOrders = async () => {
        const getBulkOrdersResponse = await fetch(BASE_URL_API + "/getBulkOrders",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getBulkOrdersResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getBulkOrdersRespo = await getBulkOrdersResponse.json()
            if (getBulkOrdersRespo.status === "success") {
                setOrdersData(getBulkOrdersRespo.response)
            } else {
                if (getBulkOrdersRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setOrdersData([])
                }
            }
            console.log(getBulkOrdersRespo)
        }
    }

    useEffect(() => {
        getBulkOrders()
    }, [])



    const rowSelectionHandler = (k) => {
        setSelectedData((prevSelectedData) =>
            prevSelectedData.some(item => item.orderIdentifier === k.orderIdentifier)
                ? prevSelectedData.filter(item => item.orderIdentifier !== k.orderIdentifier)
                : [...prevSelectedData, k]
        );
    };

    const initiateOrderProcessing = async () => {
        if (window.confirm(`Are you sure you want to process order for ${selectedData?.length}`)) {
            const opCreateBatchResponse = await fetch(BASE_URL_API+"/opCreateBatch",
            {
                method : "POST",
                body : JSON.stringify({
                    token : authCtx.token,
                    selectedData : selectedData
                })
            })

            if(!opCreateBatchResponse.ok) {
                console.log("Something went wrong : Server Error")
            } else {
                const opCreateBatchRespo = await opCreateBatchResponse.json()
                if(opCreateBatchRespo.status==="success") {
                    nav(`/marketplace/order-processing/batch/${opCreateBatchRespo?.response?.batchIdentifier}`) 
                } else {
                    if(opCreateBatchRespo?.message==="token Expired, please login again!") {
                        authCtx.logout()
                    } else {

                    }
                }
                console.log(opCreateBatchRespo)
            }
        }
    }


    return (
        <div>
            <MainHeader />

            <div className={styles.mainContainer}>
                <div className={styles.mainHeader}>
                    <div className={styles.title}>
                        View All Bulk Orders
                    </div>
                    <div className={styles.selectedTxt} >
                        {selectedData?.length} / {ordersData?.length} orders selected 
                    </div>
                    <div className={styles.btn}>
                        {selectedData?.length > 0 ?
                            <button onClick={() => initiateOrderProcessing()} className={styles.initiateBtn} >Process Order</button>
                            :
                            <button onClick={() => alert(`Please select at least one order to process them!`)} className={styles.initiateBtn}  >Process Order</button>
                        }
                        <Link to={`/marketplace/order-processing/batches`}  className={styles.initiateBtn} >View Processed Orders</Link>
                    </div>
                </div>

                <div className={styles.tableWrapper}>
                    <div className={styles.tableHeader}>
                        <div onClick={() => handleAllSelector()} className={styles.sn} >
                            {selectedData?.length === ordersData?.length ?
                                <MdCheckBox size={24} />
                                :
                                <MdCheckBoxOutlineBlank size={24} />
                            }
                        </div>
                        <div className={styles.orderId}>
                            Order Id.
                        </div>
                        <div className={styles.date}>
                            Date 
                        </div>
                        <div className={styles.date}>
                            Time
                        </div>
                        <div className={styles.item}>
                            Item
                        </div>
                        <div className={styles.pointsUsed}>
                            Points Used
                        </div>
                        <div className={styles.phone}>
                            Phone
                        </div>
                        <div className={styles.address}>
                            Delivery Address
                        </div>
                        <div className={styles.pincode}>
                            Pincode
                        </div>
                        <div className={styles.currentStatus}>
                            Current Status
                        </div>
                    </div>
                    <div className={styles.tableBody}>
                        {ordersData.map((item, index) => (
                            <EachRowViewBulkOrder
                                count={index + 1} item={item} key={index}
                                selectedItems={selectedData}
                                rowSelectionHandler={rowSelectionHandler}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BulkOrders
import React from 'react'
import MainHeader from '../../Components/Headers/MainHeader'
import FetchCsvData from '../../Components/Users/FetchCsvData'
import styles from './BulkUploadUser.module.css'

const BulkUploadUser = () => {
    return (
        <div>
            <MainHeader />
            BulkUploadUser
            <FetchCsvData />
            </div>
    )
}

export default BulkUploadUser
import React from 'react';
import styles from './DownloadSampleSheet.module.css'
const DownloadSampleSheet = () => {
    const headers = ['productTitle', 'productDescription', 'hsn', 'mrp', 'discount', 'modelNo', 'brandName', 'category', 'subCategory', 'weight', 'height', 'width', 'depth', 'warranty', 'guarantee', 'coveredInWarranty', 'notCoveredInWarranty', 'img_url_1', 'img_url_2', 'img_url_3', 'img_url_4', 'img_url_5']; // Add more headers if needed

    const generateCSV = () => {
        // Convert headers array into CSV format
        const csvContent = headers.join(',') + '\n';

        // Create a Blob with CSV content
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        // Create a download link
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'sample_sheet.csv');
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Cleanup
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    return (
        <button onClick={generateCSV} className={styles.downloadBtn} >Download Sample Sheet</button>
    );
};

export default DownloadSampleSheet;

import React, { useState } from 'react';
import { MdClose } from 'react-icons/md';
import styles from './EditProductInBulkUploading.module.css';

const EditProductInBulkUploading = ({ isOpen, item, onClose, onUpdate, rowIndex, categoryData }) => {
    const [formData, setFormData] = useState({ ...item });

    // console.log(item)
    // Handle input change
    const handleChange = (field, value) => {
        setFormData({
            ...formData,
            [field]: value,
        });
    };

    // Save form changes
    const handleSave = () => {
        console.log("Updated Product Details:", formData);
        onUpdate(formData); // Pass updated data to the parent component
        onClose();
    };

    // Close on backdrop click or Escape key
    window.onclick = (event) => (event.target.id === 'editProductInBulkUploadingIdPop' ? onClose() : null);
    document.addEventListener('keydown', (event) => {
        if (event.key === 'Escape') onClose();
    });

    if (!isOpen) return null;

    return (
        <div className={styles.mainWrapper} id="editProductInBulkUploadingIdPop">
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.mainHeader}>
                        Edit Product In Bulk Uploading
                        <button className={styles.closeButton} onClick={onClose}>
                            <MdClose size={22} color="white" />
                        </button>
                    </div>
                    <div className={styles.mainBody}>
                        <form>
                            <div className={styles.fieldGroup}>
                                <label>Product Title</label>
                                <input
                                    type="text"
                                    value={formData.productTitle}
                                    onChange={(e) => handleChange('productTitle', e.target.value)}
                                />
                            </div>
                            <div className={styles.fieldGroup}>
                                <label>Product Description</label>
                                <textarea
                                    value={formData.productDescription}
                                    onChange={(e) => handleChange('productDescription', e.target.value)}
                                />
                            </div>
                            <div className={styles.groupedFields}>
                                <div className={styles.fieldGroup}>
                                    <label>HSN</label>
                                    <input
                                        type="text"
                                        value={formData.hsn}
                                        onChange={(e) => handleChange('hsn', e.target.value)}
                                    />
                                </div>
                                <div className={styles.fieldGroup}>
                                    <label>Model Number</label>
                                    <input
                                        type="text"
                                        value={formData.modelNo}
                                        onChange={(e) => handleChange('modelNo', e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className={styles.groupedFields}>
                                <div className={styles.fieldGroup}>
                                    <label>MRP</label>
                                    <input
                                        type="number"
                                        value={formData.mrp}
                                        onChange={(e) => handleChange('mrp', e.target.value)}
                                    />
                                </div>
                                <div className={styles.fieldGroup}>
                                    <label>Discount</label>
                                    <input
                                        type="number"
                                        value={formData.discount}
                                        onChange={(e) => handleChange('discount', e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className={styles.groupedFields}>
                                <div className={styles.fieldGroup}>
                                    <label>Brand Name</label>
                                    <input
                                        type="text"
                                        value={formData.brandName}
                                        onChange={(e) => handleChange('brandName', e.target.value)}
                                    />
                                </div>
                                <div className={styles.fieldGroup}>
                                    <label>Category</label>
                                    <select
                                        value={formData.category}
                                        onChange={(e) => handleChange('category', e.target.value)}
                                    >
                                        <option value="">Select Category</option>
                                        {categoryData.map((cat, i) => (
                                            <option key={i} value={cat.category}>
                                                {cat.category}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className={styles.fieldGroup}>
                                    <label>Sub Category</label>
                                    <select
                                        value={formData.subCategory}
                                        onChange={(e) => handleChange('subCategory', e.target.value)}
                                    >
                                        <option value="">Select Sub Category</option>
                                        {categoryData
                                            .find((cat) => cat.category === formData.category)
                                            ?.subCategory.map((sub, i) => (
                                                <option key={i} value={sub}>
                                                    {sub}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>

                            <div className={styles.fieldGroup}>
                                <label>Weight (g)</label>
                                <input
                                    type="number"
                                    value={formData.weight}
                                    onChange={(e) => handleChange('weight', e.target.value)}
                                />
                            </div>
                            <div className={styles.fieldGroup}>
                                <label>Dimensions (cm)</label>
                                <div className={styles.dimensions}>
                                    <input
                                        type="number"
                                        placeholder="Height"
                                        value={formData.height}
                                        onChange={(e) => handleChange('height', e.target.value)}
                                    />
                                    <input
                                        type="number"
                                        placeholder="Width"
                                        value={formData.width}
                                        onChange={(e) => handleChange('width', e.target.value)}
                                    />
                                    <input
                                        type="number"
                                        placeholder="Depth"
                                        value={formData.depth}
                                        onChange={(e) => handleChange('depth', e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className={styles.fieldGroup}>
                                <label>Warranty</label>
                                <input
                                    type="text"
                                    value={formData.warranty}
                                    onChange={(e) => handleChange('warranty', e.target.value)}
                                />
                            </div>
                            <div className={styles.fieldGroup}>
                                <label>Guarantee</label>
                                <input
                                    type="text"
                                    value={formData.guarantee}
                                    onChange={(e) => handleChange('guarantee', e.target.value)}
                                />
                            </div>
                            <div className={styles.fieldGroup}>
                                <label>Covered in Warranty</label>
                                <textarea
                                    value={formData.coveredInWarranty}
                                    onChange={(e) => handleChange('coveredInWarranty', e.target.value)}
                                />
                            </div>
                            <div className={styles.fieldGroup}>
                                <label>Not Covered in Warranty</label>
                                <textarea
                                    value={formData.notCoveredInWarranty}
                                    onChange={(e) => handleChange('notCoveredInWarranty', e.target.value)}
                                />
                            </div>

                            <div className={styles.imagesWrapper}>
                                <div className={styles.previewImg}>
                                    <img src={formData?.img_url_1} />
                                </div>
                                <div className={styles.previewImg}>
                                    <img src={formData?.img_url_2} />
                                </div>
                                <div className={styles.previewImg}>
                                    <img src={formData?.img_url_3} />
                                </div>
                                <div className={styles.previewImg}>
                                    <img src={formData?.img_url_4} />
                                </div>
                                <div className={styles.previewImg}>
                                    <img src={formData?.img_url_5} />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className={styles.mainFooter}>
                        <button className={styles.saveButton} onClick={handleSave}>Save</button>
                        <button className={styles.cancelButton} onClick={onClose}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditProductInBulkUploading;

import React from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import styles from './EachVendorHeader.module.css'

const EachVendorHeader = (props) => {
    const { vendorIdentifier, activeNav} = useParams()
    return (
        <div className={styles.navWrapper}>
            <Link to={`/vendor/${vendorIdentifier}`} className={activeNav === '' ? styles.activeNavBtn : styles.defaultNavBtn} >Details</Link>
            <Link to={`/vendor/${vendorIdentifier}/products`} className={activeNav === 'Products' ? styles.activeNavBtn : styles.defaultNavBtn} >Products / SKUs</Link>
        </div>
    )
}

export default EachVendorHeader
import React, { useState } from 'react';
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBYlVyFseAhWBcFSsyK7-D9YVsJX6D91Os",
    authDomain: "qnnoxrewards.firebaseapp.com",
    projectId: "qnnoxrewards",
    storageBucket: "qnnoxrewards.firebasestorage.app",
    messagingSenderId: "762950026375",
    appId: "1:762950026375:web:9b22f0a7d5c8560f786422",
    measurementId: "G-REPQJT78FW"
};



// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);

const AddPicturesInBulk = () => {
    const [files, setFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState({});
    const [user, setUser] = useState(null); // Stores the authenticated user
    const [error, setError] = useState('');

    // Sign in the user
    const signIn = async () => {
        const email = "navneet@lokaci.com"; // Replace with actual credentials
        const password = "navneet"; // Replace with actual credentials

        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            setUser(userCredential.user); // Set the authenticated user
            console.log("Signed in as:", userCredential.user.email);
        } catch (err) {
            console.error("Authentication error:", err.message);
            setError(err.message);
        }
    };

    // Handle file selection
    const handleFileChange = (e) => {
        setFiles(e.target.files);
    };

    // Upload files to Firebase Storage
    const uploadFiles = () => {
        if (!user) {
            setError("You must be signed in to upload files."); // Prevent upload without authentication
            return;
        }

        Array.from(files).forEach((file) => {
            const storageRef = ref(storage, `uploads/${user.uid}/${file.name}`); // Use user-specific folder
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    // Update progress
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadProgress((prev) => ({
                        ...prev,
                        [file.name]: progress
                    }));
                    console.log(`Upload is ${progress}% done for ${file.name}`);
                },
                (error) => {
                    console.error("Upload error:", error.message);
                },
                () => {
                    // Get the download URL
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        console.log("File available at:", downloadURL);
                    });
                }
            );
        });
    };

    return (
        <div style={{ padding: '20px' }}>
            <h2>Upload Pictures in Bulk</h2>
            {error && <p style={{ color: 'red' }}>{error}</p>}

            {!user ? (
                <button onClick={signIn}>Sign In</button>
            ) : (
                <p>Signed in as: {user.email}</p> // Display the authenticated user
            )}

            <input
                type="file"
                multiple
                accept="image/*"
                onChange={handleFileChange}
                style={{ marginTop: '20px' }}
            />

            <button onClick={uploadFiles} style={{ marginTop: '10px' }}>
                Upload Files
            </button>

            <div style={{ marginTop: '20px' }}>
                <h3>Upload Progress</h3>
                {Object.keys(uploadProgress).map((fileName) => (
                    <p key={fileName}>
                        {fileName}: {Math.round(uploadProgress[fileName])}%
                    </p>
                ))}
            </div>
        </div>
    );
};

export default AddPicturesInBulk;

import React, { useState } from 'react'
import MainHeader from '../../Components/Headers/MainHeader'
import EachVendorHeader from '../../Components/Vendors/EachVendorHeader'
import styles from './ViewEachVendor.module.css'

const ViewEachVendor = () => {
    return (
        <div>
            <MainHeader />
            <EachVendorHeader />
            <div className={styles.summaryWrapper}>
                <div className={styles.summaryContainer}>
                    ViewEachVendor
                </div>
            </div>

        </div>
    )
}

export default ViewEachVendor
import React, { useContext, useEffect, useRef, useState } from 'react'
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md'
import { useParams } from 'react-router'
import MainHeader from '../../Components/Headers/MainHeader'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './OpBatchProductGrouping.module.css'

const OpBatchProductGrouping = () => {
    const { batchIdentifier } = useParams()
    const authCtx = useContext(AuthContext)
    const [productData, setProductData] = useState([])

    const [columns, setColumns] = useState([200, 200]); // Two resizable columns
    const resizerRefs = useRef([]);

    const [vendorData, setVendorData] = useState([
        {
            productIdentifier: '15',
            vendorsData: [
                {
                    vendorIdentifier: '15',
                    vendorTitle: 'Navneet Chandan',
                    vendorAddress: 'Noida',
                    vendorPincode: '201307',
                    vendorPrice: 650,
                    isSelected: false
                },
                {
                    vendorIdentifier: '24',
                    vendorTitle: 'Navneet',
                    vendorAddress: 'Noida',
                    vendorPincode: '110016',
                    vendorPrice: 550,
                    isSelected: false
                },
            ]
        },
        {
            productIdentifier: '2',
            vendorsData: []
        },
        {
            productIdentifier: '3',
            vendorsData: [
                {
                    vendorIdentifier: '23',
                    vendorTitle: 'Navneet Kumar',
                    vendorAddress: 'Noida',
                    vendorPincode: '201307',
                    vendorPrice: 650,
                    isSelected: false
                },
                {
                    vendorIdentifier: '24',
                    vendorTitle: 'Navneet Chandan',
                    vendorAddress: 'Noida',
                    vendorPincode: '110016',
                    vendorPrice: 550,
                    isSelected: false
                },
            ]
        },
    ])

    const selectVendorHandler = (productIdentifier, vendorIdentifier) => {
        setVendorData((prevVendorData) =>
            prevVendorData.map((product) => {
                // Check if this product matches the given productIdentifier
                if (product.productIdentifier === productIdentifier) {
                    return {
                        ...product,
                        vendorsData: product.vendorsData.map((vendor) => {
                            return {
                                ...vendor,
                                // Toggle selection if the same vendor is clicked, otherwise deselect all others
                                isSelected:
                                    vendor.vendorIdentifier === vendorIdentifier
                                        ? !vendor.isSelected // Toggle the clicked vendor
                                        : false, // Deselect all others
                            };
                        }),
                    };
                }
                return product; // Return other products unchanged
            })
        );
    };




    const handleMouseDown = (index) => (e) => {
        e.preventDefault();

        const startX = e.clientX;
        const initialWidths = [...columns];

        const onMouseMove = (e) => {
            const delta = e.clientX - startX;
            const newWidths = [...columns];
            newWidths[index] = Math.max(100, initialWidths[index] + delta); // Minimum width 100px
            setColumns(newWidths);
        };

        const onMouseUp = () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        };

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
    };

    const opGroupProducts = async () => {
        const opGroupProductsResponse = await fetch(BASE_URL_API + "/opGroupProducts",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    batchIdentifier: batchIdentifier
                })
            });

        if (!opGroupProductsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const opGroupProductsRespo = await opGroupProductsResponse.json()
            if (opGroupProductsRespo.status === "success") {
                setProductData(opGroupProductsRespo.response)
            } else {
                if (opGroupProductsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(opGroupProductsRespo)
        }
    }

    useEffect(() => {
        opGroupProducts()
    }, [])


    return (
        <div>
            <MainHeader />
            <div className={styles.container}>
                <div className={styles.filterWrapper}>
                    <div>

                    </div>
                    <div>
                        <button >List Vendor</button>
                    </div>
                </div>
                <div className={styles.table} style={{ height: '80vh' }}>
                    <div className={styles.header}>
                        {['Product Info', 'Vendors', 'Assigned Vendors'].map((header, index) => (
                            <div
                                key={index}
                                className={`${styles.column} ${index === 2 ? styles.flexGrow : ''
                                    }`}
                                style={index < 2 ? { width: columns[index] } : {}}
                            >
                                {header}
                                {index < 2 && (
                                    <div
                                        ref={(el) => (resizerRefs.current[index] = el)}
                                        className={styles.resizer}
                                        onMouseDown={handleMouseDown(index)}
                                    ></div>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className={styles.body}>
                        {productData?.map((row, rowIndex) => (
                            <div key={rowIndex} className={styles.row}>
                                <div
                                    className={styles.cell}
                                    style={{ width: columns[0] }}
                                >
                                    {row.productTitle} ({row?.itemCount})
                                </div>
                                <div
                                    className={styles.cell}
                                    style={{ width: columns[1] }}
                                >
                                    <div className={styles.vendorListingWrapper}>
                                        {vendorData?.find((item) => item.productIdentifier === row?.productIdentifier) ? (
                                            vendorData
                                                .find((item) => item.productIdentifier === row?.productIdentifier)
                                                ?.vendorsData?.length > 0 ? (
                                                vendorData
                                                    .find((item) => item.productIdentifier === row?.productIdentifier)
                                                    ?.vendorsData?.map((vendor, index) => (
                                                        <div key={vendor.vendorIdentifier || index} className={styles.eachVendorWrapper}
                                                            onClick={() => selectVendorHandler(row?.productIdentifier, vendor?.vendorIdentifier)}
                                                        >
                                                            <div>
                                                                {vendor?.isSelected === true ?
                                                                    <MdRadioButtonChecked size={28} />
                                                                    :
                                                                    <MdRadioButtonUnchecked size={28} />
                                                                }
                                                            </div>
                                                            <div>
                                                                <div className={styles.vendorTitle}>
                                                                    {vendor?.vendorTitle}
                                                                </div>
                                                                <div className={styles.vendorPrice}>
                                                                    ₹{vendor?.vendorPrice}
                                                                </div>
                                                                <div>
                                                                    ₹{parseFloat(vendor?.vendorPrice) * parseFloat(row?.itemCount)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                            ) : (
                                                <div>No vendors available for this product.</div>
                                            )
                                        ) : (
                                            <div>Product not found.</div>
                                        )}
                                    </div>

                                </div>
                                <div className={`${styles.cell} ${styles.flexGrow}`}>
                                    {row.column3}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default OpBatchProductGrouping
import React, { useContext, useState } from 'react'
import styles from './AddNewFolderInBucket.module.css'
import { IoIosCloseCircleOutline } from "react-icons/io";
import { sanitizeUsername } from '../../../Helpers/Calci';
import AuthContext from '../../../Store/auth-context';
import { BASE_URL_API } from '../../../References/urls'
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';


const AddNewFolderInBucket = (props) => {
    const { isOpen, onClose, onSuccess } = props
    const authCtx = useContext(AuthContext)
    const [isCreating, setIsCreating] = useState(false)
    const { bucketIdentifier } = useParams()
    const nav = useNavigate()
    

    window.onclick = event => event.target.id === "AddNewFolderInBucketPopUp" ? onClose() : null
    document.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
            onClose()
        }
    });

    const [folderName, setFolderName] = useState('')
    const createStoreFolder = async () => {
        setIsCreating(true)
        const createStoreFolderResponse = await fetch(BASE_URL_API + "/createStorageFolder",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    bucketIdentifier: bucketIdentifier,
                    folderName: folderName
                })
            });

        if (!createStoreFolderResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const createStoreFolderRespo = await createStoreFolderResponse.json()
            if (createStoreFolderRespo.status === "success") {
                nav(`/storage/buckets/${bucketIdentifier}/${folderName}`)
            } else {
                if (createStoreFolderRespo?.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    toast(createStoreFolderRespo?.message)
                }
            }
            console.log(createStoreFolderRespo)
        }
        setIsCreating(false)
    }




    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer} id="AddNewFolderInBucketPopUp">
                    <div className={styles.mainContent}>
                        <div className={styles.mainHeader}>
                            <button onClick={() => onClose()} ><IoIosCloseCircleOutline size={24} /></button>
                            Create A New Folder
                        </div>
                        <div className={styles.mainBody}>
                            <input placeholder="Enter the folder name..."
                                autoFocus
                                value={folderName}
                                onChange={(e) => setFolderName(sanitizeUsername(e.target.value))}
                            />
                        </div>
                        <div className={styles.mainFooter}>
                            {isCreating === true ?
                                <button>Creating...</button>
                                :
                                <button onClick={() => createStoreFolder()} >Create</button>
                            }
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default AddNewFolderInBucket
import React, { useContext, useEffect, useState } from 'react'
import { FaFilter } from 'react-icons/fa6'
import { MdCheckBox, MdCheckBoxOutlineBlank, MdToggleOff, MdToggleOn } from 'react-icons/md'
import { toast } from 'react-toastify'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './ViewEachVendorProductsAvailable.module.css'

const ViewEachVendorProductsAvailable = (props) => {
    const { toggleNavWrapper, vendorIdentifier } = props
    const authCtx = useContext(AuthContext)
    const [availableProducts, setAvailableProducts] = useState([])
    const [selectedAvailableItems, setSelectedAvailableItems] = useState([])

    const listAvailableProductsForVendor = async () => {
        const listAvailableProductsForVendorResponse = await fetch(BASE_URL_API + "/listAvailableProductsForVendor",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    vendorIdentifier: vendorIdentifier
                })
            });

        if (!listAvailableProductsForVendorResponse.ok) {
            console.log(`Something went wrong : Server Error`)
        } else {
            const listAvailableProductsForVendorRespo = await listAvailableProductsForVendorResponse.json()
            if (listAvailableProductsForVendorRespo.status === "success") {
                setAvailableProducts(listAvailableProductsForVendorRespo?.response)
            } else {
                if (listAvailableProductsForVendorRespo?.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAvailableProducts([])
                }
            }
            console.log(listAvailableProductsForVendorRespo)
        }
    }

    useEffect(() => {
        listAvailableProductsForVendor()
    }, [])

    const availableItemsSelectionHandler = (product) => {
        const isAlreadySelected = selectedAvailableItems.some(item => item.productIdentifier === product.productIdentifier);

        if (isAlreadySelected) {
            // Remove the item if it's already selected
            setSelectedAvailableItems(
                selectedAvailableItems.filter(item => item.productIdentifier !== product.productIdentifier)
            );
        } else {
            // Add the item with default vendorOffering and stock
            setSelectedAvailableItems([
                ...selectedAvailableItems,
                { ...product, vendorOffering: '', stock: '' }
            ]);
        }
    };

    const updateVendorOffering = (productIdentifier, value) => {
        setSelectedAvailableItems(prevItems =>
            prevItems.map(item =>
                item.productIdentifier === productIdentifier
                    ? { ...item, vendorOffering: value }
                    : item
            )
        );
    };

    const updateStock = (productIdentifier, value) => {
        setSelectedAvailableItems(prevItems =>
            prevItems.map(item =>
                item.productIdentifier === productIdentifier
                    ? { ...item, stock: value }
                    : item
            )
        );
    };

    const allAvailableSelectionHandler = () => {
        if (availableProducts?.length === selectedAvailableItems?.length) {
            setSelectedAvailableItems([])
        } else {
            setSelectedAvailableItems(
                availableProducts.map(product => ({
                    ...product,
                    vendorOffering: '',
                    stock: ''
                }))
            );
        }
    };

    const addItemsHandler = async () => {
        console.log(selectedAvailableItems)
        const addVendorProductsResponse = await fetch(BASE_URL_API+"/addVendorProducts",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                vendorIdentifier : vendorIdentifier, 
                productData : selectedAvailableItems
            })
        });

        if(!addVendorProductsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const addVendorProductsRespo = await addVendorProductsResponse.json()
            if(addVendorProductsRespo.status==="success") {
                toast(`Successfully added the products!`)
                listAvailableProductsForVendor()
            } else {
                if(addVendorProductsRespo.message==="token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(addVendorProductsRespo)
        }

    }

    

    return (
        <div>
            <div className={styles.mainHeader}>
                <div className={styles.mhLeft}>
                    <div className={styles.toggleBtn}>
                        <button onClick={() => toggleNavWrapper()}>
                            <MdToggleOn size={24} /> Listing Available Product To Add
                        </button>
                    </div>
                    <div className={styles.searchbarWrapper}>
                        <input placeholder="Search For any product" />
                    </div>
                </div>
                <div className={styles.mhRight}>
                    <div>
                        <FaFilter />
                    </div>
                    <div onClick={() => addItemsHandler()}>
                        <button className={styles.addItems}>Add {selectedAvailableItems?.length} items</button>
                    </div>
                </div>
            </div>

            <div className={styles.productTableWrapper}>
                <div className={styles.productHeaderWrapper}>
                    <div className={styles.checkBox} onClick={() => allAvailableSelectionHandler()}>
                        {selectedAvailableItems?.length === availableProducts?.length ?
                            <MdCheckBox size={22} />
                            :
                            <MdCheckBoxOutlineBlank size={22} />
                        }
                    </div>
                    <div className={styles.productTitle}>Product Title available</div>
                    <div className={styles.basePrice}>Base Price</div>
                    <div className={styles.vendorOffering}>Vendor Offering</div>
                    <div className={styles.stock}>Stock Available</div>
                </div>
                <div className={styles.productBodyWrapper}>
                    {availableProducts?.map((item, index) => (
                        <div key={index} className={styles.eachProductWrapper}>
                            <div className={styles.checkBox} onClick={() => availableItemsSelectionHandler(item)}>
                                {selectedAvailableItems.some(itemToFind => itemToFind.productIdentifier === item.productIdentifier) ? (
                                    <MdCheckBox size={20} />
                                ) : (
                                    <MdCheckBoxOutlineBlank size={20} />
                                )}
                            </div>
                            <div className={styles.productTitle}>{item?.productTitle}</div>
                            <div className={styles.basePrice}>{item?.basePrice}</div>
                            <div className={styles.vendorOffering}>
                                <input
                                    type="number"
                                    placeholder="Enter Best Price"
                                    value={
                                        selectedAvailableItems.find(product => product.productIdentifier === item.productIdentifier)?.vendorOffering || ''
                                    }
                                    onChange={(e) => updateVendorOffering(item.productIdentifier, e.target.value)}
                                />
                            </div>
                            <div className={styles.stock}>
                                <input
                                    placeholder="Enter Stock"
                                    value={
                                        selectedAvailableItems.find(product => product.productIdentifier === item.productIdentifier)?.stock || ''
                                    }
                                    onChange={(e) => updateStock(item.productIdentifier, e.target.value)}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ViewEachVendorProductsAvailable;

import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import MainHeader from '../../Components/Headers/MainHeader';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../Store/auth-context';
import styles from './OnboardNewVendor.module.css';

const OnboardNewVendor = () => {
    const authCtx = useContext(AuthContext)
    const initialFormData = {
        gstin: '',
        companyName: '',
        contactPerson: '',
        contactNo: '',
        email: '',
        registrationType: 'Regular',
        pan: '',
        billingAddress: {
            address1: '',
            address2: '',
            landmark: '',
            city: '',
            country: 'India',
            state: '',
            pincode: '',
        },
        eWayBillDistance: '',
        openingBalance: {
            balanceType: 'Credit',
            amount: 0,
        },
        shippingAddress: '',
        bankDetails: {
            bankName: '',
            ifscCode: '',
            accountNumber: '',
            branchName: '',
        },
        customFields: {
            licenseNo: '',
        },
        additionalDetails: {
            faxNo: '',
            website: '',
            creditLimit: '',
            dueDays: '',
            note: '',
        }
    }
    const [formData, setFormData] = useState(initialFormData);

    const handleInputChange = (field, value) => {
        setFormData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleNestedInputChange = (section, field, value) => {
        setFormData((prev) => ({
            ...prev,
            [section]: {
                ...prev[section],
                [field]: value,
            },
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Form Data:', formData);
        // Add submission logic here
        // const onboardVendor = async () => {
        const onboardVendorResponse = await fetch(BASE_URL_API + '/onboardVendor',
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    formData: formData
                })
            });

        if (!onboardVendorResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const onboardVendorRespo = await onboardVendorResponse.json()
            if (onboardVendorRespo.status === "success") {
                setFormData(initialFormData)
                toast('Successfully created the vendor!')
            } else {
                if (onboardVendorRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(onboardVendorRespo)

        }
        // }
    };

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <MainHeader />


                <form className={styles.formContainer} onSubmit={handleSubmit}>
                    <div className={styles.headerWrapper}>
                        <div>
                            <h2>Onboard New Vendor</h2>
                        </div>
                        <div>
                            <button type="submit" className={styles.submitButton}>
                                Submit
                            </button>
                        </div>
                    </div>

                    <div className={styles.fieldsWrapper}>


                        <div className={styles.eachColWrapper}>
                            <div className={styles.sectionTitle}>
                                <h3>Primary Details</h3>
                            </div>
                            <div className={styles.sectionBody}>
                                <div className={styles.formGroup}>
                                    <label>GSTIN</label>
                                    <input
                                        autoFocus
                                        type="text"
                                        value={formData.gstin}
                                        onChange={(e) => handleInputChange('gstin', e.target.value)}
                                    />
                                </div>

                                <div className={styles.formGroup}>
                                    <label>Company Name *</label>
                                    <input
                                        type="text"
                                        value={formData.companyName}
                                        onChange={(e) => handleInputChange('companyName', e.target.value)}
                                        placeholder="Enter company name"
                                        required
                                    />
                                </div>

                                <div className={styles.formGroup}>
                                    <label>Contact Person</label>
                                    <input
                                        type="text"
                                        value={formData.contactPerson}
                                        onChange={(e) => handleInputChange('contactPerson', e.target.value)}

                                    />
                                </div>

                                <div className={styles.formGroup}>
                                    <label>Contact No</label>
                                    <input
                                        type="tel"
                                        value={formData.contactNo}
                                        onChange={(e) => handleInputChange('contactNo', e.target.value)}
                                        placeholder="Enter contact number"
                                    />
                                </div>

                                <div className={styles.formGroup}>
                                    <label>Email</label>
                                    <input
                                        type="email"
                                        value={formData.email}
                                        onChange={(e) => handleInputChange('email', e.target.value)}
                                        placeholder="Enter email address"
                                    />
                                    <small>
                                        Note: Use a comma (,) as an address separator to enter multiple emails.
                                    </small>
                                </div>

                                <div className={styles.formGroup}>
                                    <label>Registration Type</label>
                                    <select
                                        value={formData.registrationType}
                                        onChange={(e) => handleInputChange('registrationType', e.target.value)}
                                    >
                                        <option value="Regular">Regular</option>
                                        <option value="Regular-Embassy/UN Body">Regular-Embassy/UN Body</option>
                                        <option value="Regular-SEZ">Regular-SEZ</option>
                                        <option value="Composition">Composition</option>
                                    </select>
                                </div>

                                <div className={styles.formGroup}>
                                    <label>PAN</label>
                                    <input
                                        type="text"
                                        value={formData.pan}
                                        onChange={(e) => handleInputChange('pan', e.target.value)}
                                    />
                                </div>
                            </div>

                        </div>


                        <div className={styles.eachColWrapper}>
                            <div className={styles.sectionTitle}>
                                <h3>Billing Address</h3>
                            </div>
                            <div className={styles.sectionBody}>
                                <div className={styles.formGroup}>
                                    <label>Address</label>
                                    <input
                                        type="text"
                                        value={formData.billingAddress.address1}
                                        onChange={(e) =>
                                            handleNestedInputChange('billingAddress', 'address1', e.target.value)
                                        }
                                        placeholder="Enter address"
                                    />
                                    <input
                                        type="text"
                                        value={formData.billingAddress.address2}
                                        onChange={(e) =>
                                            handleNestedInputChange('billingAddress', 'address2', e.target.value)
                                        }
                                        placeholder="Enter address line 2"
                                    />
                                </div>

                                <div className={styles.formGroup}>
                                    <label>City</label>
                                    <input
                                        type="text"
                                        value={formData.billingAddress.city}
                                        onChange={(e) =>
                                            handleNestedInputChange('billingAddress', 'city', e.target.value)
                                        }
                                        placeholder="Enter city"
                                        required
                                    />
                                </div>

                                <div className={styles.formGroup}>
                                    <label>State *</label>
                                    <input
                                        type="text"
                                        value={formData.billingAddress.state}
                                        onChange={(e) =>
                                            handleNestedInputChange('billingAddress', 'state', e.target.value)
                                        }
                                        placeholder="Enter state"
                                        required
                                    />
                                </div>

                                <div className={styles.formGroup}>
                                    <label>Country *</label>
                                    <input type="text" value="India" readOnly />
                                </div>

                                <div className={styles.formGroup}>
                                    <label>Pincode *</label>
                                    <input
                                        type="number"
                                        value={formData.billingAddress.pincode}
                                        onChange={(e) =>
                                            handleNestedInputChange('billingAddress', 'pincode', e.target.value)
                                        }
                                        placeholder="Enter pincode"
                                        required
                                    />
                                </div>
                            </div>

                        </div>

                        <div className={styles.eachColWrapper}>
                            <div className={styles.sectionTitle}>
                                <h3>Shipping Address</h3>
                            </div>
                            <div className={styles.sectionBody}>

                                <div className={styles.formGroup}>
                                    <label>Shipping Address</label>
                                    <textarea
                                        value={formData.shippingAddress}
                                        onChange={(e) => handleInputChange('shippingAddress', e.target.value)}
                                        placeholder="Enter shipping address"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.eachColWrapper}>

                            <div className={styles.sectionTitle}>
                                <h3>Bank Details</h3>
                            </div>
                            <div className={styles.sectionBody}>
                                {/* Bank Details */}
                                <div className={styles.formGroup}>
                                    <label>Bank Name</label>
                                    <input
                                        type="text"
                                        value={formData.bankDetails.bankName}
                                        onChange={(e) =>
                                            handleNestedInputChange('bankDetails', 'bankName', e.target.value)
                                        }
                                        placeholder="Enter bank name"
                                    />
                                </div>

                                <div className={styles.formGroup}>
                                    <label>IFSC Code</label>
                                    <input
                                        type="text"
                                        value={formData.bankDetails.ifscCode}
                                        onChange={(e) =>
                                            handleNestedInputChange('bankDetails', 'ifscCode', e.target.value)
                                        }
                                        placeholder="Enter IFSC code"
                                    />
                                </div>

                                <div className={styles.formGroup}>
                                    <label>Account Number</label>
                                    <input
                                        type="text"
                                        value={formData.bankDetails.accountNumber}
                                        onChange={(e) =>
                                            handleNestedInputChange('bankDetails', 'accountNumber', e.target.value)
                                        }
                                        placeholder="Enter account number"
                                    />
                                </div>

                                <div className={styles.formGroup}>
                                    <label>Branch Name</label>
                                    <input
                                        type="text"
                                        value={formData.bankDetails.branchName}
                                        onChange={(e) =>
                                            handleNestedInputChange('bankDetails', 'branchName', e.target.value)
                                        }
                                        placeholder="Enter branch name"
                                    />
                                </div>
                            </div>
                        </div>


                        <div className={styles.eachColWrapper}>
                            <div className={styles.sectionTitle}>

                                <h3>Custom Fields</h3>
                            </div>
                            <div className={styles.sectionBody}>
                                {/* Custom Fields */}
                                <div className={styles.formGroup}>
                                    <label>License No.</label>
                                    <input
                                        type="text"
                                        value={formData.customFields.licenseNo}
                                        onChange={(e) =>
                                            handleNestedInputChange('customFields', 'licenseNo', e.target.value)
                                        }
                                        placeholder="Enter License No."
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.eachColWrapper}>
                            <div className={styles.sectionTitle}>
                                {/* Additional Details */}
                                <h3>Additional Details</h3>

                            </div>
                            <div className={styles.sectionBody}>
                                <div className={styles.formGroup}>
                                    <label>Fax No.</label>
                                    <input
                                        type="text"
                                        value={formData.additionalDetails.faxNo}
                                        onChange={(e) =>
                                            handleNestedInputChange('additionalDetails', 'faxNo', e.target.value)
                                        }
                                        placeholder="Enter Fax No."
                                    />
                                </div>

                                <div className={styles.formGroup}>
                                    <label>Website</label>
                                    <input
                                        type="url"
                                        value={formData.additionalDetails.website}
                                        onChange={(e) =>
                                            handleNestedInputChange('additionalDetails', 'website', e.target.value)
                                        }
                                        placeholder="Enter Website URL"
                                    />
                                </div>

                                <div className={styles.formGroup}>
                                    <label>Credit Limit</label>
                                    <input
                                        type="number"
                                        value={formData.additionalDetails.creditLimit}
                                        onChange={(e) =>
                                            handleNestedInputChange('additionalDetails', 'creditLimit', e.target.value)
                                        }
                                        placeholder="Enter Credit Limit"
                                    />
                                </div>

                                <div className={styles.formGroup}>
                                    <label>Due Days</label>
                                    <input
                                        type="number"
                                        value={formData.additionalDetails.dueDays}
                                        onChange={(e) =>
                                            handleNestedInputChange('additionalDetails', 'dueDays', e.target.value)
                                        }
                                        placeholder="Enter Due Days"
                                    />
                                </div>

                                <div className={styles.formGroup}>
                                    <label>Note</label>
                                    <textarea
                                        value={formData.additionalDetails.note}
                                        onChange={(e) =>
                                            handleNestedInputChange('additionalDetails', 'note', e.target.value)
                                        }
                                        placeholder="Enter Note"
                                    />
                                </div>

                            </div>
                        </div>


                    </div>


                </form>
            </div>
        </div>
    )
};

export default OnboardNewVendor;

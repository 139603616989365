import React, { useContext, useEffect, useState } from 'react'
import MainHeader from '../../Components/Headers/MainHeader'
import DateRangeSelector from '../../Components/UI/DateRangeSelector'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './Kpis.module.css'
import { BiCalendar } from 'react-icons/bi';
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from 'recharts'
import DatamapsIndia from 'react-datamaps-india'
import { Link } from 'react-router-dom'
import ThatsAll from '../../Components/UI/ThatsAll'


const Kpis = () => {

    const authCtx = useContext(AuthContext)
    const [ordersData, setOrdersData] = useState([])
    const [gmvData, setGmvData] = useState([])


    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
    const closeModal = () => { setIsDatePickerOpen(false) }
    const [dates, setDates] = useState({
        "startDate": "",
        "endDate": ""
    })

    const changeDateHandler = (s, e) => {
        setDates({
            "startDate": s,
            "endDate": e
        })
    }



    const getBulkOrders = async () => {
        const getBulkOrdersResponse = await fetch(BASE_URL_API + "/getBulkOrders",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getBulkOrdersResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getBulkOrdersRespo = await getBulkOrdersResponse.json()
            if (getBulkOrdersRespo.status === "success") {
                setOrdersData(getBulkOrdersRespo.response)
                getTotalGmv()
            } else {
                if (getBulkOrdersRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setOrdersData([])
                }
            }
            console.log(getBulkOrdersRespo)
        }
    }

    const getTotalGmv = async () => {
        const getTotalGmvResponse = await fetch(BASE_URL_API + "/getTotalGmv",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getTotalGmvResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getTotalGmvRespo = await getTotalGmvResponse.json()
            if (getTotalGmvRespo.status === "success") {
                setGmvData(getTotalGmvRespo.response)
            } else {
                if (getTotalGmvRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setGmvData([])
                }
            }
            console.log(getTotalGmvRespo)
        }
    }

    useEffect(() => {
        getBulkOrders()
    }, [])

    const data = [
        {
            "name": "Page A",
            "uv": 4000,
            "pv": 2400
        },
        {
            "name": "Page B",
            "uv": 3000,
            "pv": 1398
        },
        {
            "name": "Page C",
            "uv": 2000,
            "pv": 9800
        },
        {
            "name": "Page D",
            "uv": 2780,
            "pv": 3908
        },
        {
            "name": "Page E",
            "uv": 1890,
            "pv": 4800
        },
        {
            "name": "Page F",
            "uv": 2390,
            "pv": 3800
        },
        {
            "name": "Page G",
            "uv": 3490,
            "pv": 4300
        }
    ]



    return (
        <div>
            <MainHeader />
            <div className={styles.mainContainer}>
                <div className={styles.mainHeader}>

                    <div className={styles.dateWrapper}>
                        <div className={styles.dateRangeSelectorWrapperBtn}
                            onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
                        >
                            {dates.startDate === dates.endDate ?
                                <div>
                                    {dates.startDate} &nbsp;
                                </div>
                                :
                                <div>
                                    From {dates.startDate} to {dates.endDate} &nbsp;
                                </div>
                            }

                            <BiCalendar />
                        </div>
                        <DateRangeSelector
                            isOpen={isDatePickerOpen}
                            closeModal={closeModal}
                            changeDateHandler={changeDateHandler}
                        />

                    </div>
                </div>
                <div className={styles.cardsWrapper}>
                    <div className={`${styles.eachCardWrapper} ${styles.oneThird}`}>
                        <div>
                            Sale - {dates?.startDate} - {dates?.endDate}
                        </div>
                        <div >
                            <div>
                                ₹
                            </div>
                            <div>
                                <BarChart width={400} height={200} data={data}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    {/* <YAxis /> */}
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="pv" fill="#8884d8" />
                                    <Bar dataKey="uv" fill="#82ca9d" />
                                </BarChart>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.eachCardWrapper} ${styles.oneThird}`}>
                        <div>
                            Purchase - {dates?.startDate} - {dates?.endDate}
                        </div>
                        <div>

                            <div>

                            </div>
                            <div></div>
                        </div>
                    </div>
                    <div className={`${styles.eachCardWrapper} ${styles.oneThird}`}>
                        <div>
                            Expense - {dates?.startDate} - {dates?.endDate}
                        </div>
                        <div>

                            <div>

                            </div>
                            <div></div>
                        </div>
                    </div>
                    <div className={`${styles.eachCardWrapper} ${styles.half}`}>
                        <div className={styles.cardBody}>
                            <div>Heat Map</div>
                            <div className={styles.mapWrapper}>
                                <DatamapsIndia

                                    regionData={{
                                        Maharashtra: {
                                            value: 10,
                                        },
                                    }}
                                    hoverComponent={({ value }) => {
                                        return (
                                            <>
                                                <p>{value.name}</p>
                                                <p>{value.value}</p>
                                            </>
                                        )
                                    }}
                                    mapLayout={{
                                        title: 'Total Sales',
                                        legendTitle: 'Total Sales',
                                        startColor: '#FFDAB9',
                                        endColor: '#FF6347',
                                        hoverTitle: 'Count',
                                        noDataColor: '#f5f5f5',
                                        borderColor: '#8D8D8D',
                                        hoverBorderColor: '#8D8D8D',
                                        hoverColor: 'green',
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={`${styles.eachCardWrapper} ${styles.half}`}>
                        <div>
                            New Vs Existing Customer Summary
                        </div>
                        <div>
                            <BarChart width={600} height={500} data={data}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="pv" fill="#8884d8" />
                                <Bar dataKey="uv" fill="#82ca9d" />
                            </BarChart>
                        </div>
                    </div>

                    <div className={`${styles.eachCardWrapper} ${styles.half}`}>
                        <div>Invoice Count Summary</div>
                        <div>

                        </div>
                    </div>

                    <div className={`${styles.eachCardWrapper} ${styles.half}`}>
                        <div>Invoice Amount Summary</div>
                        <div>

                        </div>
                    </div>
                    <div className={`${styles.eachCardWrapper} ${styles.oneThird}`}>
                        <div>Best Selling Product</div>
                        <div>

                        </div>
                    </div>
                    <div className={`${styles.eachCardWrapper} ${styles.oneThird}`}>
                        <div>Least Selling Product</div>
                        <div>

                        </div>
                    </div>
                    <div className={`${styles.eachCardWrapper} ${styles.oneThird}`}>
                        <div>Low on Stock</div>
                        <div>

                        </div>
                    </div>

                    <div className={`${styles.eachCardWrapper} ${styles.half}`}>
                        <div>Top Customers</div>
                        <div>

                        </div>
                    </div>

                    <div className={`${styles.eachCardWrapper} ${styles.half}`}>
                        <div>Top Vendors</div>
                        <div>

                        </div>
                    </div>

                    <div className={`${styles.eachCardWrapper} ${styles.full}`}>
                        <div className={styles.cardTitle}>Sales Invoice Due</div>
                        <div>
                            <div className={styles.tableHeaderWrapper}>
                                <div>Invoice No.</div>
                                <div>Company Name</div>
                                <div>Name</div>
                                <div>Phone</div>
                                <div>Due Date</div>
                                <div>Due From</div>
                                <div>Remaining Payment</div>
                            </div>
                            <div>

                                <div>

                                </div>
                            </div>
                        </div>
                        <div className={styles.ecwFooter}>
                            <div>
                                Total Outstanding : ₹
                            </div>
                            <div className={styles.viewAll}>
                                <Link to={'/sales-invoices'}>View All</Link>
                            </div>
                        </div>
                    </div>

                    <div className={`${styles.eachCardWrapper} ${styles.full}`}>
                        <div className={styles.cardTitle}>Purchase Invoice Due</div>
                        <div>
                            <div className={styles.tableHeaderWrapper}>
                                <div>Invoice No.</div>
                                <div>Company Name</div>
                                <div>Name</div>
                                <div>Phone</div>
                                <div>Due Date</div>
                                <div>Due From</div>
                                <div>Remaining Payment</div>
                            </div>

                        </div>
                        <div className={styles.ecwFooter}>
                            <div>
                                Total Outstanding : ₹
                            </div>
                            <div className={styles.viewAll}>
                                <Link to={'/sales-invoices'}>View All</Link>
                            </div>
                        </div>
                    </div>





                </div>

                <ThatsAll height={200} />


                {/* <div className={styles.mainContent}>
                    <div className={styles.eachCardWrapper}>
                        <div>
                            Total Orders
                        </div>

                        <div className={styles.value}>
                            {ordersData?.length}
                        </div>
                    </div>

                    <div className={styles.eachCardWrapper}>
                        <div>
                            Total GMV
                        </div>

                        <div className={styles.value}>
                            ₹{gmvData?.totalGmv}
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default Kpis
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import MainHeader from '../../Components/Headers/MainHeader'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './OpEachBatch.module.css'

const OpEachBatch = () => {
    const { batchIdentifier } = useParams()
    // opGetAllOrdersInABatch

    const authCtx = useContext(AuthContext)
    const [allOrdersInABatch, setAllOrdersInABatch] = useState([])
    const opGetAllOrdersInABatch = async () => {
        const opGetAllOrdersInABatchResponse = await fetch(BASE_URL_API + "/opGetAllOrdersInABatch",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    batchIdentifier: batchIdentifier
                })
            });

        if (!opGetAllOrdersInABatchResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const opGetAllOrdersInABatchRespo = await opGetAllOrdersInABatchResponse.json()
            if (opGetAllOrdersInABatchRespo.status === "success") {
                setAllOrdersInABatch(opGetAllOrdersInABatchRespo?.response)
            } else {
                if (opGetAllOrdersInABatchRespo?.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(opGetAllOrdersInABatchRespo)
        }
    }

    useEffect(() => {
        opGetAllOrdersInABatch()
    }, [batchIdentifier])


    return (
        <div>
            <MainHeader />
            <div className={styles.mainContainer}>
                <div className={styles.header}>
                    <div>
                        Showing all order in the batch with Batch Id : {batchIdentifier}
                    </div>
                    <div>
                        <Link className={styles.groupingBtn} to={`/marketplace/order-processing/batch/${batchIdentifier}/grouping`} >Grouping</Link>
                    </div>
                </div>
                <div>
                    <div className={styles.tableHeader}>
                        <div className={styles.orderIdentifier}>Order Id</div>
                        <div className={styles.placedOn}>Placed On</div>
                        <div className={styles.placedAt}>Placed At</div>
                        <div className={styles.totalItem}>Total Items</div>
                    </div>
                    <div>
                        {allOrdersInABatch?.map((item, index) => (
                            <div key={index} className={styles.eachOrderWrapper}>
                                <div className={styles.orderIdentifier}>
                                    {item?.orderIdentifier}
                                </div>
                                <div className={styles.placedOn}>
                                    {item?.createdOn?.substring(0, 10)}
                                </div>
                                <div className={styles.placedAt}>
                                    {item?.createdOn?.substring(11, 20)}
                                </div>
                                <div className={styles.totalItem}>
                                    {item?.totalItems}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OpEachBatch
import React, { useState } from 'react'
import styles from './TrackOrder.module.css'

const TrackOrder = () => {
    const [vendorAuthToken, setVendorAuthToken] = useState('')

    const getVendorAuthToken = async () => {
        const getVendorAuthTokenResponse = await fetch(`https://shipment.xpressbees.com/api/users/login`,
            {
                method: "POST",
                body: JSON.stringify({
                    email: `surendra@caliberinno.com`,
                    password: `Kiara@2020`
                })
            });

        if (!getVendorAuthTokenResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getVendorAuthTokenRespo = await getVendorAuthTokenResponse.json()
            setVendorAuthToken(getVendorAuthTokenRespo?.data)
            console.log(getVendorAuthTokenRespo)
        }
    }
    
    const getAllCourier = async () => {
        const getAllCourierResponse = await fetch(`https://shipment.xpressbees.com/api/courier`,
            {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${vendorAuthToken} d`, // Replace with your actual Bearer token
                    "Content-Type": "application/json" // Optional, depending on the API's requirements
                }
            });

        if (!getAllCourierResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllCourierRespo = await getAllCourierResponse.json()
            console.log(getAllCourierRespo)
        }
    }
   
    const trackShipment = async () => {
        const trackShipmentResponse = await fetch(`https://shipment.xpressbees.com/api/shipments2/track/14355940104535`,
            {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${vendorAuthToken}`, // Replace with your actual Bearer token
                }
            });

        if (!trackShipmentResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const trackShipmentRespo = await trackShipmentResponse.json()
            console.log(trackShipmentRespo)
        }
    }

    return (
        <div>
            TrackOrder
            <div>
                {vendorAuthToken}
            </div>
            <div>
                <button onClick={() => getVendorAuthToken()} >Get token</button>
            </div>
            <div>
                <button onClick={() => getAllCourier()} >Get Courier list</button>
            </div>
          
            <div>
                <button onClick={() => trackShipment()} >Track Shipment</button>
            </div>
        </div>
    )
}

export default TrackOrder
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router'
import MainHeader from '../../Components/Headers/MainHeader'
import EachVendorHeader from '../../Components/Vendors/EachVendorHeader'
import styles from './ViewEachVendorProducts.module.css'
import { MdCheckBox, MdCheckBoxOutlineBlank, MdToggleOff, MdToggleOn } from 'react-icons/md'
import { FaFilter } from 'react-icons/fa6'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import ViewEachVendorProductsAdded from '../../Components/Vendors/ViewEachVendorProductsAdded'
import ViewEachVendorProductsAvailable from '../../Components/Vendors/ViewEachVendorProductsAvailable'

const ViewEachVendorProducts = () => {
    const authCtx = useContext(AuthContext)
    const { vendorIdentifier, activeNav } = useParams()
    const [showingAdded, setShowingAdded] = useState(false)
    
    
    
    
    const toggleNavWrapper = () => {
        setShowingAdded(!showingAdded)
    }

    // addVendorProducts


    // getVendorProducts

    

    
    

    return (
        <div>
            <MainHeader />
            <EachVendorHeader />
            <div>
                {showingAdded === true ?
                    <ViewEachVendorProductsAdded toggleNavWrapper={toggleNavWrapper} vendorIdentifier={vendorIdentifier} />
                    :
                    <ViewEachVendorProductsAvailable toggleNavWrapper={toggleNavWrapper} vendorIdentifier={vendorIdentifier} />
                }
            </div>
        </div>
    )
}

export default ViewEachVendorProducts
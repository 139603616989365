// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBYlVyFseAhWBcFSsyK7-D9YVsJX6D91Os",
    authDomain: "qnnoxrewards.firebaseapp.com",
    projectId: "qnnoxrewards",
    storageBucket: "qnnoxrewards.firebasestorage.app",
    messagingSenderId: "762950026375",
    appId: "1:762950026375:web:9b22f0a7d5c8560f786422",
    measurementId: "G-REPQJT78FW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);  // Auth initialization
const storage = getStorage(app);  // Storage initialization

export { auth, storage };

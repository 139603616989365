import React, { useState } from "react";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import {
    getStorage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
} from "firebase/storage";
import styles from "./UploadContentInBulkFolderWise.module.css";
import { toast } from "react-toastify";

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBYlVyFseAhWBcFSsyK7-D9YVsJX6D91Os",
    authDomain: "qnnoxrewards.firebaseapp.com",
    projectId: "qnnoxrewards",
    storageBucket: "qnnoxrewards.firebasestorage.app",
    messagingSenderId: "762950026375",
    appId: "1:762950026375:web:9b22f0a7d5c8560f786422",
    measurementId: "G-REPQJT78FW",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);

const UploadContentInBulkFolderWise = (props) => {
    const { folderIdentifier, isOpen, onClose } = props;
    const [files, setFiles] = useState([]);
    const [previewUrls, setPreviewUrls] = useState([]);
    const [uploadProgress, setUploadProgress] = useState({});
    const [overallProgress, setOverallProgress] = useState(0);
    const [user, setUser] = useState(null);
    const [error, setError] = useState("");

    const signIn = async () => {
        const email = "navneet@lokaci.com";
        const password = "navneet";

        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            setUser(userCredential.user);
            console.log("Signed in as:", userCredential.user.email);
            setError('')
        } catch (err) {
            console.error("Authentication error:", err.message);
            setError(err.message);
        }
    };

    const handleFileChange = (e) => {
        const newFiles = Array.from(e.target.files);
        setFiles([...files, ...newFiles]);
        const newPreviews = newFiles.map((file) => URL.createObjectURL(file));
        setPreviewUrls([...previewUrls, ...newPreviews]);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const newFiles = Array.from(e.dataTransfer.files);
        setFiles([...files, ...newFiles]);
        const newPreviews = newFiles.map((file) => URL.createObjectURL(file));
        setPreviewUrls([...previewUrls, ...newPreviews]);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const uploadFiles = () => {
        if (!user) {
            setError("You must be signed in to upload files.");
            return;
        }

        const totalFiles = files.length;
        let uploadedFiles = 0;

        files.forEach((file) => {
            const storageRef = ref(storage, `uploads/${user.uid}/${folderIdentifier}/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadProgress((prev) => ({
                        ...prev,
                        [file.name]: progress,
                    }));

                    // Calculate overall progress
                    const totalProgress = Object.values(uploadProgress).reduce(
                        (acc, curr) => acc + curr,
                        progress // Include the current file's progress
                    );
                    setOverallProgress(totalProgress / totalFiles);
                },
                (error) => {
                    console.error("Upload error:", error.message);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        console.log("File available at:", downloadURL);
                        uploadedFiles++;
                        if (uploadedFiles === totalFiles) {
                            toast(`All files successfully uploaded!`)
                            setOverallProgress(100);
                            onClose()
                        }
                    });
                }
            );
        });
    };

    const removeFile = (index) => {
        setFiles(files.filter((_, i) => i !== index));
        setPreviewUrls(previewUrls.filter((_, i) => i !== index));
        const newProgress = { ...uploadProgress };
        delete newProgress[files[index]?.name];
        setUploadProgress(newProgress);
    };

    const clearFiles = () => {
        setFiles([]);
        setPreviewUrls([]);
        setUploadProgress({});
        setOverallProgress(0);
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.mainHeader}>
                        <div>Upload Pictures in Bulk</div>
                        {error && <span style={{ color: "red", fontSize: '12px' }}>{error}</span>}
                        {!user ? (
                            <button onClick={signIn}>Sign In</button>
                        ) : (
                            // <p>Signed in as: {user.email}</p>
                            <p>You are signed-in!</p>
                        )}
                        <button className={styles.closeBtn} onClick={() => onClose()}>
                            Close
                        </button>
                    </div>
                    <div className={styles.mainBody}>
                        <div
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                            className={styles.dropZone}
                        >
                            <p>Drag and drop images here, or click to select files</p>
                            <input
                                type="file"
                                multiple
                                accept="image/*"
                                onChange={handleFileChange}
                                style={{ display: "none" }}
                                id="fileInput"
                            />
                            <label htmlFor="fileInput" className={styles.fileInputLabel}>
                                Select Files
                            </label>
                        </div>

                        {previewUrls.length > 0 && (
                            <div className={styles.previewContainer}>
                                <div className={styles.previewHeader}>
                                    <div>Selected Images</div>
                                    <button onClick={clearFiles} className={styles.clearButton}>
                                        Clear All
                                    </button>
                                </div>
                                <div className={styles.previewGrid}>
                                    {previewUrls.map((url, index) => (
                                        <div key={index} className={styles.previewItem}>
                                            <div className={styles.previewImg}>
                                                <img src={url} alt={`Preview ${index + 1}`} />
                                            </div>
                                            <div className={styles.progress}>
                                                {uploadProgress[files[index]?.name]
                                                    ? `${Math.round(
                                                        uploadProgress[files[index].name]
                                                    )}%`
                                                    : "Not uploaded"}
                                            </div>
                                            <button
                                                onClick={() => removeFile(index)}
                                                className={styles.removeButton}
                                            >
                                                ✕
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                    </div>
                    <div className={styles.mainFooter}>
                        <div className={styles.overallProgress}>
                            Overall Progress: {Math.round(overallProgress)}%
                        </div>
                        <button onClick={uploadFiles} className={styles.uploadBtn}>
                            Upload Files
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UploadContentInBulkFolderWise;

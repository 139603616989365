import React, { useContext, useState } from 'react';
import Papa from 'papaparse'; // CSV parsing library
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../Store/auth-context';

const FetchCsvData = () => {
    const authCtx = useContext(AuthContext)
    const [data, setData] = useState([]);
    const [error, setError] = useState('');

    // Function to handle file upload
    const handleFileUpload = (event) => {
        const file = event.target.files[0];

        if (file) {
            Papa.parse(file, {
                header: true, // CSV file has headers
                skipEmptyLines: true, // Skip empty lines
                complete: (result) => {
                    console.log('Total Rows Parsed:', result.data.length); // Log the total number of rows parsed
                    console.log('Parsed Data:', result.data); // Log the entire parsed data

                    const filteredData = result.data.map((row) => {
                        return {
                            phoneNumber: row.phoneNumber || '',
                            firstName: row.firstName || '',
                            middleName: row.middleName || '',
                            lastName: row.lastName || '',
                            fullName: row.fullName || '',
                            gender: row.gender || '',
                            firstLine: row.firstLine || '',
                            secondLine: row.secondLine || '',
                            city: row.city || '',
                            state: row.state || '',
                            country: row.country || '',
                            pincode: row.pincode || '',
                            address : row.fullAddress || `${row.firstLine}, ${row.secondLine}, ${row.city}, ${row.state}, ${row.country}, ${row.pincode}`
                        };
                    });
                    setData(filteredData);
                },
                error: (err) => {
                    setError('Error parsing CSV file.');
                    console.error('Parsing error:', err);
                },
            });
        } else {
            setError('Please upload a valid CSV file.');
        }
    };

    const uploadClientsBulkUser = async () => {
        const uploadClientsBulkUserResponse = await fetch(BASE_URL_API+"/uploadClientsBulkUser",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                clientIdentifier : '1729505352',
                users : data
            })
        });

        if(!uploadClientsBulkUserResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const uploadClientsBulkUserRespo = await uploadClientsBulkUserResponse.json()
            if(uploadClientsBulkUserRespo.status==="success") {

            } else {
                if(uploadClientsBulkUserRespo?.message==="token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    
                }
            }
            console.log(uploadClientsBulkUserRespo)

        }
    }

    console.log(data)

    return (
        <div>
            <h2>Upload CSV File</h2>
            <button onClick={() => uploadClientsBulkUser()}>
                Upload Data
            </button>
            <input
                type="file"
                accept=".csv"
                onChange={handleFileUpload}
            />
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <div>
                {data.length > 0 && (
                    <table border="1">
                        <thead>
                            <tr>
                                <th>Phone Number</th>
                                <th>First Name</th>
                                <th>Middle Name</th>
                                <th>Last Name</th>
                                <th>Full Name</th>
                                <th>Gender</th>
                                <th>First Line</th>
                                <th>Second Line</th>
                                <th>City</th>
                                <th>State</th>
                                <th>Country</th>
                                <th>Pincode</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((row, index) => (
                                <tr key={index}>
                                    <td>{row.phoneNumber}</td>
                                    <td>{row.firstName}</td>
                                    <td>{row.middleName}</td>
                                    <td>{row.lastName}</td>
                                    <td>{row.fullName}</td>
                                    <td>{row.gender}</td>
                                    <td>{row.firstLine}</td>
                                    <td>{row.secondLine}</td>
                                    <td>{row.city}</td>
                                    <td>{row.state}</td>
                                    <td>{row.country}</td>
                                    <td>{row.pincode}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default FetchCsvData;

import React, { useContext } from 'react'
import { Routes, Route } from "react-router-dom";
import AnalyticsCodeScanned from '../Pages/Analytics/AnalyticsCodeScanned';
import AnalyticsSummary from '../Pages/Analytics/AnalyticsSummary';
import { AnalyticsUser } from '../Pages/Analytics/AnalyticsUser';
import Kpis from '../Pages/Analytics/Kpis';
import Login from '../Pages/Authentication/Login';
import AllChallans from '../Pages/Challans/AllChallans';
import GenerateChallans from '../Pages/Challans/GenerateChallans';
import AllClients from '../Pages/Clients/AllClients';
import ClientAuth from '../Pages/Clients/ClientAuth';
import ClientProducts from '../Pages/Clients/ClientProducts';
import OnboardNewClients from '../Pages/Clients/OnboardNewClients';
import AddPicturesInBulk from '../Pages/CloudStorage/AddPicturesInBulk';
import AllBuckets from '../Pages/CloudStorage/BucketMaster/AllBuckets';
import EachBucket from '../Pages/CloudStorage/BucketMaster/EachBucket';
import FolderData from '../Pages/CloudStorage/BucketMaster/FolderData';
import CsLogin from '../Pages/CloudStorage/CsAuthentication/CsLogin';
import AllTickets from '../Pages/CustomerSupport/AllTickets';
import AddProducts from '../Pages/Marketplace/AddProducts';
import AddProductsInBulk from '../Pages/Marketplace/AddProductsInBulk';
import AllInvoices from '../Pages/Marketplace/AllInvoices';
import BulkOrders from '../Pages/Marketplace/BulkOrders';
import Cart from '../Pages/Marketplace/Cart';
import CreateNewOrder from '../Pages/Marketplace/CreateNewOrder';
import CreateOrder from '../Pages/Marketplace/CreateOrder';
import EditProducts from '../Pages/Marketplace/EditProducts';
import ManageShipping from '../Pages/Marketplace/ManageShipping';
import OrderDetails from '../Pages/Marketplace/OrderDetails';
import Payments from '../Pages/Marketplace/Payments';
import ViewOrders from '../Pages/Marketplace/ViewOrders';
import ViewProducts from '../Pages/Marketplace/ViewProducts';
import TrackOrder from '../Pages/OrderTracking/TrackOrder';
import GenerateQRCodes from '../Pages/QRCode/GenerateQRCodes';
import CreateNewSchemeNOffers from '../Pages/SchemeAndOffers/CreateNewSchemeNOffers';
import SchemesNOffers from '../Pages/SchemeAndOffers/SchemesNOffers';
import BulkUploadUser from '../Pages/UserPages/BulkUploadUser';
import CreateNewUser from '../Pages/UserPages/CreateNewUser';
import Home from '../Pages/UserPages/Home';
import Users from '../Pages/UserPages/Users';
import AllVendors from '../Pages/Vendors/AllVendors';
import OnboardNewVendor from '../Pages/Vendors/OnboardNewVendor';
import AuthContext from '../Store/auth-context';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import OpEachBatch from '../Pages/OrderProcessing/OpEachBatch';
import OpAllBatches from '../Pages/OrderProcessing/OpAllBatches';
import OpBatchProductGrouping from '../Pages/OrderProcessing/OpBatchProductGrouping';
import ViewEachVendor from '../Pages/Vendors/ViewEachVendor';
import ViewEachVendorProducts from '../Pages/Vendors/ViewEachVendorProducts';


const MainNavigator = () => {
    const authCtx = useContext(AuthContext);
    let isLoggedIn = authCtx.isLoggedIn;

    console.log(authCtx)

    if (!isLoggedIn) {
        return (
            <Routes>
                <Route path="*" element={<Login />}></Route>
            </Routes>
        )
    } else {
        return (
            <>
                <ToastContainer />
                <Routes>
                    {/* <Route path="*" element={<Login />}></Route> */}
                    <Route path="/" element={<Home />}></Route>
                    {/* <Route path="/login" element={<Login />}></Route> */}
                    <Route path="/home" element={<Home />}></Route>

                    <Route path="/analytics/summary" element={<AnalyticsSummary />}></Route>
                    <Route path="/analytics/users" element={<AnalyticsUser />}></Route>
                    <Route path="/analytics/codes-scanned" element={<AnalyticsCodeScanned />}></Route>


                    <Route path="/users" element={<Users />}></Route>
                    <Route path="/users/create-new-user" element={<CreateNewUser />}></Route>
                    <Route path="/users/bulk-upload" element={<BulkUploadUser />}></Route>

                    <Route path="/marketplace/all-products" element={<ViewProducts />}></Route>
                    <Route path="/marketplace/add-products" element={<AddProducts />}></Route>
                    <Route path="/marketplace/edit-products" element={<EditProducts />}></Route>
                    <Route path="/marketplace/view-orders" element={<ViewOrders />}></Route>

                    {/* adding this on 4th june of 2024 */}
                    <Route path="/marketplace/create-order" element={<CreateNewOrder />}></Route>
                    <Route path="/marketplace/create-order/:orderIdentifier" element={<CreateOrder />}></Route>
                    <Route path="/marketplace/cart/:orderIdentifier" element={<Cart />}></Route>
                    <Route path="/marketplace/bulk-orders" element={<BulkOrders />}></Route>



                    <Route path="/schemes-and-offers" element={<SchemesNOffers />}></Route>
                    <Route path="/schemes-and-offers/create-a-new" element={<CreateNewSchemeNOffers />}></Route>

                    <Route path="/qrc/generate" element={<GenerateQRCodes />}></Route>

                    <Route path="/client-products" element={<ClientProducts />}></Route>



                    <Route path="/support-tickets" element={<AllTickets />}></Route>


                    {/* adding this on 2nd of May 2024 */}
                    <Route path="/clients" element={<AllClients />}></Route>
                    <Route path="/clients/onboard" element={<OnboardNewClients />}></Route>
                    <Route path="/clients/:clientIdentifier/auth" element={<ClientAuth />}></Route>


                    <Route path="/vendors" element={<AllVendors />}></Route>
                    <Route path="/vendors/onboard" element={<OnboardNewVendor />}></Route>
                    <Route path="/vendor/:vendorIdentifier" element={<ViewEachVendor />}></Route>
                    <Route path="/vendor/:vendorIdentifier/products" element={<ViewEachVendorProducts />}></Route>

                    {/* adding this on today 8th June 2024 */}
                    <Route path="/kpis" element={<Kpis />}></Route>
                    <Route path="/marketplace/bulk-order/:orderIdentifier" element={<OrderDetails />}></Route>

                    {/* adding this on today 22nd October 2024 */}
                    <Route path="/marketplace/bulk-order/:orderIdentifier/track" element={<TrackOrder />}></Route>

                    {/* adding this on today 28th June 2024 */}
                    <Route path="/marketplace/bulk-order/:orderIdentifier/manage-shipping" element={<ManageShipping />}></Route>

                    <Route path="/marketplace/invoices" element={<AllInvoices />}></Route>
                    <Route path="/marketplace/invoice/:orderIdentifier" element={<AllInvoices />}></Route>
                    <Route path="/marketplace/payments" element={<Payments />}></Route>

                    {/* adding this on today 17th Nov 2024 */}
                    <Route path="/marketplace/add-products-in-bulk" element={<AddProductsInBulk />}></Route>


                    <Route path="/manage/challans" element={<AllChallans />}></Route>
                    <Route path="/challans/generate" element={<GenerateChallans />}></Route>


                    <Route path="/storage-login" element={<CsLogin />}></Route>
                    <Route path="/storage-buckets" element={<AllBuckets />}></Route>
                    <Route path="/storage/buckets/:bucketIdentifier" element={<EachBucket />}></Route>
                    <Route path="/storage/buckets/:bucketIdentifier/:folderIdentifier" element={<FolderData />}></Route>
                    <Route path="/add-pic-in-bulk" element={<AddPicturesInBulk />}></Route>


                    {/* adding this on today 25th November 2024 */}
                    <Route path="/marketplace/order-processing/batches" element={<OpAllBatches />}></Route>
                    <Route path="/marketplace/order-processing/batch/:batchIdentifier" element={<OpEachBatch />}></Route>
                    <Route path="/marketplace/order-processing/batch/:batchIdentifier/grouping" element={<OpBatchProductGrouping />}></Route>
                    
                    
                    
                    





                </Routes>
            </>
        )
    }
}

export default MainNavigator
import React from 'react'
import MainHeader from '../../Components/Headers/MainHeader'
import styles from './Payments.module.css'

const Payments = () => {
    return (
        <div>
            <MainHeader />
            <div>
                Payment / Transactions
            </div>
        </div>
    )
}

export default Payments